// Import Statements
import React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import "./DashBoard.css";
import Drawer from "./components/drawer/Drawer";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Routing from "../routing/Routing";
import { Grid } from "@mui/material";
import { useLocation } from "react-router-dom";

function DashBoard() {
  let location = useLocation();
  // state Variables
  const drawerWidth = 215;
  // const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const isAccountPage = location.pathname.startsWith("/myAccount");
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        {!isAccountPage ? <Header /> : null}
        <Box
          component="nav"
          sx={{
            width: { sm: isAccountPage ? 150 : drawerWidth },
            flexShrink: { sm: 0 },
          }}
          aria-label="mailbox folders"
        >
          {!isAccountPage ? <Drawer /> : null}
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
          }}
        >
          <Grid
            sx={{
              minHeight: "100vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Grid>
              <Toolbar />
              <Routing />
            </Grid>
            <Footer />
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default DashBoard;

import React, { useState } from "react";
import {
  Collapse,
  Divider,
  Drawer as MuiDrawer,
  Grid,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import { Link, useLocation } from "react-router-dom";

import {
  DASHBOARDELEMENTS,
  SETTINGSELEMENTS,
} from "../../data/dashBoardElements";
import logo from "../../../../assets/enfeclogo.png";
import "./Drawer.css";

const Drawer = () => {
  const drawerWidth = 215;
  let location = useLocation();

  const [openItems, setOpenItems] = useState(
    DASHBOARDELEMENTS.reduce((acc, element) => {
      acc[element.elementId] = true;
      return acc;
    }, {}),
  );

  // Initialize state for which subHeaders are active
  const isSubHeaderActive = (path) => {
    return location.pathname.startsWith(path);
  };

  // Handle click to toggle specific item open/close
  const handleClick = (e) => {
    setOpenItems((prevState) => ({
      ...prevState,
      // Toggle the clicked item's state
      [e]: !prevState[e],
    }));
  };

  return (
    <MuiDrawer
      variant="permanent"
      className="listItems listItemsHover"
      sx={{
        display: { xs: "none", sm: "block" },
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          width: drawerWidth,
          background: "rgb(3, 27, 78)",
        },
      }}
      open
    >
      <div className=" listItems" style={{ height: "100vh" }}>
        <List className="rootBar" sx={{ width: "100%" }}>
          <div
            className="px-2"
            style={{
              cursor: "pointer",
            }}
          >
            <img src={logo} className="rounded-circle logo " alt="enfeclogo" />
          </div>
          <Grid className="mt-4">
            {DASHBOARDELEMENTS.length > 0 &&
              DASHBOARDELEMENTS.map((resp) => (
                <React.Fragment key={resp.elementId}>
                  <ListItem
                    sx={{ padding: 0 }}
                    button
                    onClick={() => handleClick(resp.elementId)}
                  >
                    <ListItemText>
                      <h5 className="mainHeaderList">
                        {resp.mainHeader.toUpperCase()}
                      </h5>
                    </ListItemText>
                    {openItems[resp.elementId] ? (
                      <ExpandLessOutlinedIcon
                        sx={{ fontSize: "1.1rem", width: "3rem" }}
                      />
                    ) : (
                      <ExpandMoreOutlinedIcon
                        sx={{ fontSize: "1.1rem", width: "3rem" }}
                      />
                    )}
                  </ListItem>

                  {resp.subHeaders.map((key, index) => (
                    <Collapse
                      key={index}
                      in={openItems[resp.elementId]}
                      timeout="auto"
                      unmountOnExit
                    >
                      <ul className="subheaderul">
                        <Link
                          to={key.path}
                          style={{
                            textDecoration: "none",
                          }}
                          button
                        >
                          <li
                            className={
                              key.subHeader === "New Project"
                                ? ""
                                : isSubHeaderActive(key.path)
                                  ? "selectedItem subHeaderli"
                                  : "subHeaderli"
                            }
                            style={{ fontSize: "14px" }}
                          >
                            <span
                              className={
                                key.subHeader === "New Project"
                                  ? "NewProject"
                                  : ""
                              }
                            >
                              {" "}
                              {key.icon && (
                                <span className="addicon">
                                  {" "}
                                  {React.cloneElement(key.icon, {
                                    fontSize: "small",
                                  })}
                                </span>
                              )}
                              {key.subHeader}
                            </span>
                          </li>
                        </Link>
                      </ul>
                    </Collapse>
                  ))}
                  <Grid className="py-2">
                    <Divider className="dividerColor" />
                  </Grid>
                </React.Fragment>
              ))}
          </Grid>
          {SETTINGSELEMENTS.length > 0 &&
            SETTINGSELEMENTS.map((key) => (
              <React.Fragment key={key.elementName}>
                <ul className="subheaderul">
                  <li
                    className="subHeaderli"
                    style={{
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                  >
                    {key.elementName}
                  </li>
                </ul>
              </React.Fragment>
            ))}
        </List>
      </div>
    </MuiDrawer>
  );
};

export default Drawer;

import {
  AppBar,
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import "./Header.css";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import Toolbar from "@mui/material/Toolbar";
import { useStore } from "../../../../store/store";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { COOKIES_KEYS } from "../../../../utils";
import { useLogoutApi } from "../../../../api/iam/apiHooks";

const Header = () => {
  const drawerWidth = 215;

  return (
    <>
      <AppBar
        className="appbar"
        position="fixed"
        sx={{
          height: "69px",
          bgcolor: "white",
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Grid className="d-flex justify-content-between">
          <HeaderSearch />
          <Grid className=" justify-content-end">
            <Toolbar
              sx={{
                height: "69px",
                "@media (min-width: 0px)": {
                  paddingRight: "47px",
                  paddingLeft: 0,
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                  gap: 2.1,
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <Button
                    className="create_button"
                    sx={{ display: { xs: "none", sm: "block" } }}
                  >
                    <span className="create_name">Create</span>
                    <span className="expand_icon">
                      <ExpandMoreOutlinedIcon />
                    </span>
                  </Button>

                  <IconButton
                    edge="end"
                    color="#808080"
                    aria-label="info"
                    sx={{ pl: 1 }}
                  >
                    <HelpOutlineOutlinedIcon className="HelpOutIcon" />
                  </IconButton>

                  <IconButton
                    edge="end"
                    color="#808080"
                    aria-label="notifications"
                    sx={{ pr: 1.5 }}
                  >
                    <NotificationsNoneOutlinedIcon
                      sx={{ fontSize: "2.0rem" }}
                    />
                  </IconButton>
                </Box>
                <span className="top-nav-partition"></span>

                <Box sx={{ display: "flex", alignItems: "center", gap: 0.7 }}>
                  <Stack direction="column">
                    <Typography
                      noWrap
                      component="div"
                      sx={{
                        color: "#031b4e",
                        fontSize: "16px",
                        display: "flex",
                        justifyContent: "flex-end",
                        fontWeight: 600,
                      }}
                    >
                      Product Team
                    </Typography>
                    {/* <Typography
                      variant="subtitle2"
                      noWrap
                      component="div"
                      sx={{
                        color: "#5b6987",
                        fontSize: "14px",
                        mt: -0.6,
                        pr: -1.5,
                        letterSpacing: 0,
                      }}
                    >
                      Estimated costs: $0.00
                    </Typography> */}
                  </Stack>
                  <IconButton
                    // onClick={handleClick}
                    size="medium"
                    sx={{ ml: 1, mr: { xs: 2, sm: -0.8 }, p: 0 }}
                    aria-haspopup="true"
                  >
                    <Avatar
                      sx={{
                        bgcolor: "rgb(180, 88, 252)",
                        color: "black",
                        width: 32,
                        height: 32,
                        fontSize: "11px",
                      }}
                    >
                      PT
                    </Avatar>
                  </IconButton>
                </Box>
                <Box sx={{ ml: -1 }}>
                  <DropdownMenu />
                </Box>
              </Box>
            </Toolbar>
          </Grid>
        </Grid>
      </AppBar>
    </>
  );
};

export default Header;

//Header Search Component
const HeaderSearch = () => {
  return (
    <>
      <Grid className="search1Grid mt-3 ">
        <Grid className="searchGrid">
          <SearchOutlinedIcon
            sx={{
              width: 26, // Set the desired width
              height: 35, // Set the desired height
              color: "rgb(131, 144, 175)",
            }}
            className="iconStyles"
          />
        </Grid>
        <input
          className="inpuStyles"
          placeholder="Search by resource name or public IP (Ctrl+B)"
        />
      </Grid>
    </>
  );
};

//Dropdown Menu Component
const DropdownMenu = () => {
  let history = useNavigate();
  const authStore = useStore((state) => state.setAuth);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const { mutate: logoutMutation } = useLogoutApi();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    const payload = {
      logout_all: true,
    };

    logoutMutation(payload, {
      onSuccess: () => {
        authStore(false);
        handleClose();
        history("/");

        // Remove cookies
        Cookies.remove(COOKIES_KEYS.email);
        Cookies.remove(COOKIES_KEYS.accessToken);
        Cookies.remove(COOKIES_KEYS.activationId);
      },
      onError: (error) => {
        console.error("Logout error:", error);
      },
    });
  };

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        // fontSize="small"
        sx={{
          display: { xs: "none", sm: "block" },
          padding: "0px",
          "&:hover": { backgroundColor: "transparent" },
          color: "#8390af",
          // height: "2.4rem",
        }}
      >
        {open ? (
          <ExpandLessIcon sx={{ fontSize: "1.2rem" }} />
        ) : (
          <ExpandMoreIcon sx={{ fontSize: "1.2rem" }} />
        )}
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        sx={{
          top: "17px",
          ".MuiList-root": {
            padding: "0px",
          },
        }}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            sx: {
              minHeight: 48 * 4.5,
              width: "260px",
              boxShadow: " 0 2px 8px rgba(0, 0, 0, .1)",
              border: "1px solid #dfdfdf",
              borderRadius: "3px",
            },
          },
          elevation: 0,
          sx: {
            overflow: "visible",
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem className="menu_head" onClick={handleClose}>
          <IconButton
            // onClick={handleClick}
            size="small"
            sx={{ mr: 1, padding: "0px" }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }}>J</Avatar>
          </IconButton>
          <Stack direction="column">
            <Typography
              noWrap
              component="div"
              sx={{ color: "#031b4e", fontSize: "16px", display: "flex" }}
            >
              <strong>Product Team</strong>
            </Typography>
            <Typography
              variant="subtitle2"
              noWrap
              component="div"
              sx={{ mt: -0.9, color: "#5b6987", fontSize: "14px" }}
            >
              <span style={{ borderBottom: "1px dashed #999" }}>Member</span>
            </Typography>
          </Stack>
        </MenuItem>
        <MenuItem
          className="menu_item"
          onClick={() => {
            history("/myAccount/profile");
            handleClose();
          }}
        >
          My Account
        </MenuItem>
        <MenuItem className="menu_item" onClick={handleClose}>
          Feature Preview
        </MenuItem>
        <MenuItem
          className="menu_item"
          onClick={handleClose}
          sx={{
            color: "#0069ff",
            "&:hover": {
              // Add hover effect specifically for this MenuItem
              backgroundColor: "#e5e8ed", // Light background color on hover
              fontWeight: 600, // Change font weight on hover
              color: "#0069ff",
            },
          }}
        >
          Create a Team
        </MenuItem>
        <MenuItem className="menu_item" onClick={handleLogout}>
          Sign Out
        </MenuItem>
      </Menu>
    </>
  );
};

import React from "react";
import { Box, Typography } from "@mui/material";
import logo from "../../../assets/enfeclogo.png";
import { Link } from "react-router-dom";

const LoginHeader = ({ signupText = "Sign up", signinText = "Sign In" }) => {
  return (
    <>
      <div className="navBar">
        <Box className="d-flex justify-content-center align-items-center">
          <img
            src={logo}
            className="rounded-circle header-logo"
            alt="enfeclogo"
          />
          <Typography className="headerHeading px-2" sx={{ fontWeight: 600 }}>
            IAM
          </Typography>
        </Box>
        <Typography sx={{ fontSize: "14px" }}>
          {signupText} &nbsp;{" "}
          <Link
            to={
              window.location.pathname === "/accountCreate" ||
              window.location.pathname === "/signUp"
                ? "/"
                : "/accountCreate"
            }
            className="signupBotton"
          >
            {signinText}
          </Link>
        </Typography>
      </div>
    </>
  );
};

export default LoginHeader;

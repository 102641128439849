import { Button, Divider, Grid, Typography } from "@mui/material";
import React from "react";

const Authentication = () => {
  return (
    <div>
      <Grid className="row">
        <Grid className="col-lg-3">
          <Typography sx={{ fontWeight: 600 }}>
            Two-factor authentication
          </Typography>
        </Grid>
        <Grid className="col-lg-7">
          <Typography>
            {`Add an extra layer of security to your account. To sign in, you'll
          need to provide a`}
            <br />
            code along with your username and password.
          </Typography>
        </Grid>

        <Grid className="col-lg-2 d-flex justify-content-end">
          <Button className="btnStyle" disabled={true}>
            Set Up 2FA
          </Button>
        </Grid>
      </Grid>
      <Grid className="py-3">
        <Divider sx={{ borderColor: "gray" }} />
      </Grid>
    </div>
  );
};

export default Authentication;

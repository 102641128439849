import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  Grid,
  IconButton,
  ListItem,
} from "@mui/material";
import "./UserInviteModal.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useInviteSelectedUsers } from "../../../../api/iam/apiHooks";
import { validateEmail } from "../../../../utils";
const UserInviteModal = ({ open, onClose, organizationListData }) => {
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [organizationId, setOrganizationId] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [expanded, setExpanded] = useState(false);
  const { mutate: selectInvitationData } = useInviteSelectedUsers();

  const handleEmailChange = (event) => {
    const inputEmail = event.target.value;
    setEmail(inputEmail);
    setIsEmailValid(validateEmail(inputEmail));
  };

  const handleClose = () => {
    setOrganizationName("");
    setEmail("");
    setIsEmailValid(false);
    setIsClosing(true);
    setOrganizationId("");
    setExpanded(false);
    setTimeout(() => {
      onClose();
      setIsClosing(false);
    }, 600);
  };

  const handleOrganizationChange = (event) => {
    if (event.currentTarget.getAttribute("data-id") === "") {
      setOrganizationName("");
      setOrganizationId("");
    } else {
      const selectedId = event.currentTarget.getAttribute("data-id");
      const selectedName = event.currentTarget.getAttribute("data-name");
      setOrganizationName(selectedName);
      setOrganizationId(selectedId);
      setExpanded(false);
    }
  };

  const handleInviteUsers = () => {
    const payload = {
      email,
      id: organizationId,
    };
    if (email.length === 0) return;
    selectInvitationData(payload, {
      onSuccess: (body) => {
        toast.success(body.data.message);
        handleClose();
      },
      onError: (error) => {
        toast.error(error.message);
        handleClose();
      },
    });
  };

  if (!organizationListData)
    return (
      <Grid>
        <b className="text-danger">No data available</b>
      </Grid>
    );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="invite-modal-title"
      aria-describedby="invite-modal-description"
      BackdropProps={{
        sx: { backgroundColor: "rgba(230, 230, 230, 0.6)" },
      }}
    >
      <Box className={`box-container ${isClosing ? "closing" : ""}`}>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton
            onClick={handleClose}
            sx={{ position: "absolute", top: 8, right: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography variant="h6" component="h2">
          Invite Users
        </Typography>
        <Typography variant="body1" gutterBottom>
          Invite users by email
        </Typography>

        <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography>
              {" "}
              {organizationName ? ` ${organizationName}` : "Select"}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {organizationListData.data.length > 0 ? (
              <>
                <div
                  style={{
                    maxHeight: "150px",
                    overflowY:
                      organizationListData.data.length > 3
                        ? "scroll"
                        : "visible",
                  }}
                >
                  <ListItem
                    data-id=""
                    data-name="Select"
                    onClick={handleOrganizationChange}
                    sx={{ color: "rgba(0, 0, 0, 0.5)", px: 0 }}
                  >
                    Select
                  </ListItem>

                  {organizationListData.data.map((org) => (
                    <ListItem
                      key={org.id}
                      data-id={org.id}
                      data-name={org.name}
                      onClick={handleOrganizationChange}
                      sx={{ px: 0 }}
                    >
                      {org.name}
                    </ListItem>
                  ))}
                </div>
              </>
            ) : (
              <Typography>No organizations available</Typography>
            )}
          </AccordionDetails>
        </Accordion>

        <Box>
          <Grid className="textfieldDottoedLine search_container mt-4">
            <input
              type="text"
              placeholder="Find people"
              className="inputStyles1 px-2"
              value={email}
              onChange={handleEmailChange}
            />
          </Grid>

          <Box
            sx={{
              mt: 3,
              mb: 2,
              display: "flex",
              gap: 2,
              justifyContent: "space-between",
            }}
          >
            <Button
              className="cancel-button"
              onClick={handleClose}
              sx={{ flexGrow: 1 }}
            >
              Cancel
            </Button>
            <Button
              sx={{
                flexGrow: 1,
                backgroundColor: isEmailValid ? "rgb(10, 166, 83)" : "#ecf1f1",
                color: "white",
                "&:hover": {
                  backgroundColor: isEmailValid ? "rgb(9, 142, 71)" : "#ecf1f1",
                  cursor: isEmailValid ? "pointer" : "not-allowed",
                },
                height: "45px",
              }}
              onClick={handleInviteUsers}
              disabled={!isEmailValid || !organizationId} // Ensure both email and organization are valid
            >
              Invite
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default UserInviteModal;

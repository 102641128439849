import { Link } from "react-router-dom";
import React from "react";
import { Grid, Typography, List, ListItem, ListItemText } from "@mui/material";

const Droplets = () => {
  const dropletsData = [
    { id: 1, name: "Droplet 1" },
    { id: 2, name: "Droplet 2" },
    { id: 3, name: "Droplet 3" },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4">Droplets</Typography>
      </Grid>
      <Grid item xs={12}>
        <List>
          {dropletsData.map((droplet) => (
            <ListItem key={droplet.id}>
              <ListItemText>
                <Link to={`/droplets/dropletDetails/${droplet.id}`}>
                  {droplet.name}
                </Link>
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </Grid>
      {/* Include the Outlet here for rendering child routes */}
      {/* <Grid item xs={12}>
        <Outlet />
      </Grid> */}
    </Grid>
  );
};

export default Droplets;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRedirectUrlApi } from "../api/iam/apiHooks"; // Adjust the import path as necessary
import Loader from "../pages/dashBoard/components/loader/Loader";
import OAuthSignUp from "../pages/signUp/components/OAuthSignUp";
import { useStore } from "../store/store";
import Cookies from "js-cookie";
import { COOKIES_KEYS } from "../utils";

const Callback = () => {
  const [additionalData, setAdditionalData] = useState({});
  const authStore = useStore((state) => state.setAuth);
  const navigate = useNavigate();
  const queryString = window.location.search;
  const { data, isLoading, isError } = useRedirectUrlApi(queryString);

  useEffect(() => {
    if (data && data.action === "login") {
      Cookies.set(COOKIES_KEYS.accessToken, data.data.access_token);
      authStore(true);
      navigate("/dashboard");
    } else if (data && data.action === "signup") {
      setAdditionalData(data.data || {});
    }
  }, [data, isError, navigate]);

  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    navigate("/infoPage");
  }
  if (data && data.action === "signup") {
    return (
      <OAuthSignUp
        additionalData={additionalData}
        onSuccess={() => navigate("/dashboard")}
      />
    );
  }

  return null;
};

export default Callback;

import { useOAuthUrlApi } from "../api/iam/apiHooks";

const useSocialAuth = () => {
  const {
    mutateAsync: getOAuthUrlMutate,
    isFetching,
    isError,
  } = useOAuthUrlApi();

  const loginWithSocialMedia = async (provider, purpose) => {
    const payload = { oauth_provider: provider, oauth_purpose: purpose };
    try {
      const data = await getOAuthUrlMutate(payload);
      window.location.href = data.oauth_url;
    } catch (error) {
      console.error("Error occurred during social authentication:", error);
    }
  };

  return {
    loginWithSocialMedia,
    isFetching,
    isError,
  };
};

export default useSocialAuth;

import axios from "axios";
import Cookies from "js-cookie";
import { COOKIES_KEYS } from "../utils";
export const getApiMethod = (url, params, headers) => {
  const accessToken = Cookies.get(COOKIES_KEYS.accessToken);
  return axios({
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
      ...headers,
    },
    url: url,
    method: "GET",
    params: params,
  });
};

export const postApiMethod = (
  url,
  body = null,
  params = null,
  headers = {},
) => {
  const accessToken = Cookies.get(COOKIES_KEYS.accessToken);
  return axios({
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
      ...headers,
    },
    url: url,
    method: "POST",
    data: body,
    params: params,
  });
};

export const putApiMethod = (url, body) => {
  const accessToken = Cookies.get(COOKIES_KEYS.accessToken);
  return axios({
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    url: url,
    method: "PUT",
    data: body,
  });
};
export const deleteApiMethod = (url, params) => {
  const accessToken = Cookies.get(COOKIES_KEYS.accessToken);
  return axios({
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    url: url,
    method: "DELETE",
    params: params,
  });
};

import { Divider, Grid, Typography } from "@mui/material";
import React from "react";
import "./Profile.css";
import Name from "../name/Name";
import Password from "../password/Password";
import Authentication from "../authentication/Authentication";
import { useStore } from "../../../../store/store";

export const Profile = () => {
  const isprofileDetails = useStore((state) => state.profileDetails);

  return (
    <Grid>
      <Name />
      <Grid className="row">
        <Grid className="col-lg-3">
          <Typography sx={{ fontWeight: 600 }}>Email</Typography>
        </Grid>
        <Grid className="col-lg-6">
          <Typography sx={{ fontWeight: 600 }}>
            {isprofileDetails.email}
          </Typography>
        </Grid>
        <Grid className="col-lg-2"></Grid>
        <Grid className="py-3">
          <Divider sx={{ borderColor: "gray" }} />
        </Grid>
      </Grid>
      <Password />
      <Authentication />
    </Grid>
  );
};

import Cookies from "js-cookie";

export const useAuthStore = (set) => ({
  isAuthenticated: Cookies.get("isAuth") === "true",
  setAuth: (auth) => {
    set({ isAuthenticated: auth });
    if (auth) {
      Cookies.set("isAuth", "true", { expires: 1 }); // Set cookie for 1 day
    } else {
      Cookies.remove("isAuth"); // Remove the cookie
    }
  },
});

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 90vh !important;
    width: 100% !important;
 }`, "",{"version":3,"sources":["webpack://./src/pages/dashBoard/components/loader/Loader.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,kCAAkC;IAClC,8BAA8B;IAC9B,uBAAuB;IACvB,sBAAsB;CACzB","sourcesContent":[".loader{\n    display: flex !important;\n    justify-content: center !important;\n    align-items: center !important;\n    height: 90vh !important;\n    width: 100% !important;\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-main-div {
  all: unset;
  width: 100%;
  display: flex;
  position: relative;
  box-sizing: border-box;
  background-color: rgb(255, 255, 255);
  cursor: text;
  border: none;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  height: 48px;
  padding: 0px;
  gap: 16px;
  border-width: 1px;
  /* border-style: solid; */
  border-color: rgb(91, 105, 135);
  border-image: initial;
}
.input-main-div:hover {
  border-color: rgb(0, 97, 235) !important;
}
.common-password-field {
  transition: unset;
  outline: none;
  width: 100%;
  height: 100%;
  display: block;
  border: unset;
  box-shadow: unset;
  line-height: 1.5;
  resize: none;
  border-radius: none;
  padding: 0px 16px 0px 0px;
}
.common-password-field::placeholder {
  font-size: 0.91rem;
  font-weight: 400;
  position: relative;
  left: 0.5rem;
}

.icons-styles {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  padding-left: 18px;
}
`, "",{"version":3,"sources":["webpack://./src/components/customInput/CustomInput.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,WAAW;EACX,aAAa;EACb,kBAAkB;EAClB,sBAAsB;EACtB,oCAAoC;EACpC,YAAY;EACZ,YAAY;EACZ,2BAA2B;EAC3B,4BAA4B;EAC5B,8BAA8B;EAC9B,+BAA+B;EAC/B,YAAY;EACZ,YAAY;EACZ,SAAS;EACT,iBAAiB;EACjB,yBAAyB;EACzB,+BAA+B;EAC/B,qBAAqB;AACvB;AACA;EACE,wCAAwC;AAC1C;AACA;EACE,iBAAiB;EACjB,aAAa;EACb,WAAW;EACX,YAAY;EACZ,cAAc;EACd,aAAa;EACb,iBAAiB;EACjB,gBAAgB;EAChB,YAAY;EACZ,mBAAmB;EACnB,yBAAyB;AAC3B;AACA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,aAAa;EAEb,mBAAmB;EACnB,cAAc;EACd,kBAAkB;AACpB","sourcesContent":[".input-main-div {\n  all: unset;\n  width: 100%;\n  display: flex;\n  position: relative;\n  box-sizing: border-box;\n  background-color: rgb(255, 255, 255);\n  cursor: text;\n  border: none;\n  border-top-left-radius: 3px;\n  border-top-right-radius: 3px;\n  border-bottom-left-radius: 3px;\n  border-bottom-right-radius: 3px;\n  height: 48px;\n  padding: 0px;\n  gap: 16px;\n  border-width: 1px;\n  /* border-style: solid; */\n  border-color: rgb(91, 105, 135);\n  border-image: initial;\n}\n.input-main-div:hover {\n  border-color: rgb(0, 97, 235) !important;\n}\n.common-password-field {\n  transition: unset;\n  outline: none;\n  width: 100%;\n  height: 100%;\n  display: block;\n  border: unset;\n  box-shadow: unset;\n  line-height: 1.5;\n  resize: none;\n  border-radius: none;\n  padding: 0px 16px 0px 0px;\n}\n.common-password-field::placeholder {\n  font-size: 0.91rem;\n  font-weight: 400;\n  position: relative;\n  left: 0.5rem;\n}\n\n.icons-styles {\n  display: flex;\n  -webkit-box-align: center;\n  align-items: center;\n  flex-shrink: 0;\n  padding-left: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { useState } from "react";

export const useErrorMessage = () => {
  const [error, setError] = useState(null);

  const handleError = (error) => {
    if (error.status === 401) {
      setError({ message: "Unauthorized", status: 401 });
    } else if (error.status === 409) {
      setError({ message: "Email already exists", status: 409 });
    } else {
      setError({
        message: error.message || "An error occurred",
        status: error.status || 500,
      });
    }
  };

  const clearError = () => {
    setError(null);
  };

  return { error, handleError, clearError };
};

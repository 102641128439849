// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  height: 3vh;
  border-top: 1px solid #f1f1f1;
}
.footerShadow {
  border-top: 1px solid rgb(241, 241, 241);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  height: 2.2rem;
}

.footerElement {
  color: #676767 !important;
  font-weight: 400 !important;
  font-size: 0.82rem;
}
.footerElement:hover {
  color: #0069ff !important;
}

.ulItems {
  flex-wrap: wrap;
  display: flex;
  margin: 0px;
  justify-content: center;
}

.listyles {
  list-style-type: none;
  margin-right: 0.6rem;
  cursor: pointer;
  font-size: 14px;
  margin-top: 0.9rem;
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashBoard/components/footer/Footer.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,6BAA6B;AAC/B;AACA;EACE,wCAAwC;EACxC,mBAAmB;EACnB,sBAAsB;EACtB,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,2BAA2B;EAC3B,kBAAkB;AACpB;AACA;EACE,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,aAAa;EACb,WAAW;EAEX,uBAAuB;AACzB;;AAEA;EACE,qBAAqB;EACrB,oBAAoB;EACpB,eAAe;EACf,eAAe;EACf,kBAAkB;AACpB","sourcesContent":[".footer {\n  height: 3vh;\n  border-top: 1px solid #f1f1f1;\n}\n.footerShadow {\n  border-top: 1px solid rgb(241, 241, 241);\n  padding-top: 0.5rem;\n  padding-bottom: 0.5rem;\n  height: 2.2rem;\n}\n\n.footerElement {\n  color: #676767 !important;\n  font-weight: 400 !important;\n  font-size: 0.82rem;\n}\n.footerElement:hover {\n  color: #0069ff !important;\n}\n\n.ulItems {\n  flex-wrap: wrap;\n  display: flex;\n  margin: 0px;\n  -webkit-box-pack: center;\n  justify-content: center;\n}\n\n.listyles {\n  list-style-type: none;\n  margin-right: 0.6rem;\n  cursor: pointer;\n  font-size: 14px;\n  margin-top: 0.9rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

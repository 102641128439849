export const FOOTERELEMENTS = [
  {
    subId: 1,
    footer: "Support",
    path: "/Support",
  },
  {
    subId: 2,
    footer: "Status",
    path: "/Status",
  },
  {
    subId: 3,
    footer: "Docs",
    path: "/Docs",
  },
  {
    subId: 4,
    footer: "Tutorials",
    path: "/Tutorials",
  },
  {
    subId: 5,
    footer: "Blog",
    path: "/Blog",
  },
  {
    subId: 6,
    footer: "Pricing",
    path: "/Pricing",
  },
  {
    subId: 7,
    footer: "Careers",
    path: "/Careers",
  },
  {
    subId: 8,
    footer: "Teams",
    path: "/Teams",
  },
  {
    subId: 9,
    footer: "Privacy",
    path: "/Privacy",
  },

  {
    subId: 10,
    footer: "Refer You Friends for $",
    path: "/ReferYouFriend",
  },
];

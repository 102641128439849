// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.textFieldProfile {
  height: 48px !important;
  /* width: 100% !important; */
  border: 1px solid rgb(135, 148, 176) !important;
  /* border-radius: 8px !important; */
  background: rgb(255, 255, 255) !important;
  box-sizing: border-box !important;
  position: relative !important;
  z-index: 1 !important;
  display: block !important;
  padding: 0 16px !important;
  color: rgb(3, 27, 78) !important;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}

.textfieldProfileDottoedLine:focus-within {
  outline: rgb(91, 105, 135) dotted 2px !important;
  outline-offset: 1px !important;
  border-radius: none !important;
  z-index: 2 !important;
}
.textfieldProfileDottoedLine:hover {
  border-color: rgb(0, 97, 235) !important;
}
.errorTextFieldDottedLine:focus-within {
  outline: rgb(91, 105, 135) dotted 2px !important;
  outline-offset: 1px !important;
  border-radius: none !important;
  z-index: 2 !important;
}
.errorTextFieldDottedLine:hover {
  border: 1px solid red !important;
}
.errorTextField {
  border: 1px solid red !important;
}

.textFieldProfile::placeholder {
  font-size: 0.91rem;
  font-weight: 400;
}
`, "",{"version":3,"sources":["webpack://./src/pages/myAccount/components/name/Name.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,4BAA4B;EAC5B,+CAA+C;EAC/C,mCAAmC;EACnC,yCAAyC;EACzC,iCAAiC;EACjC,6BAA6B;EAC7B,qBAAqB;EACrB,yBAAyB;EACzB,0BAA0B;EAC1B,gCAAgC;EAChC,qEAAqE;AACvE;;AAEA;EACE,gDAAgD;EAChD,8BAA8B;EAC9B,8BAA8B;EAC9B,qBAAqB;AACvB;AACA;EACE,wCAAwC;AAC1C;AACA;EACE,gDAAgD;EAChD,8BAA8B;EAC9B,8BAA8B;EAC9B,qBAAqB;AACvB;AACA;EACE,gCAAgC;AAClC;AACA;EACE,gCAAgC;AAClC;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":[".textFieldProfile {\n  height: 48px !important;\n  /* width: 100% !important; */\n  border: 1px solid rgb(135, 148, 176) !important;\n  /* border-radius: 8px !important; */\n  background: rgb(255, 255, 255) !important;\n  box-sizing: border-box !important;\n  position: relative !important;\n  z-index: 1 !important;\n  display: block !important;\n  padding: 0 16px !important;\n  color: rgb(3, 27, 78) !important;\n  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) !important;\n}\n\n.textfieldProfileDottoedLine:focus-within {\n  outline: rgb(91, 105, 135) dotted 2px !important;\n  outline-offset: 1px !important;\n  border-radius: none !important;\n  z-index: 2 !important;\n}\n.textfieldProfileDottoedLine:hover {\n  border-color: rgb(0, 97, 235) !important;\n}\n.errorTextFieldDottedLine:focus-within {\n  outline: rgb(91, 105, 135) dotted 2px !important;\n  outline-offset: 1px !important;\n  border-radius: none !important;\n  z-index: 2 !important;\n}\n.errorTextFieldDottedLine:hover {\n  border: 1px solid red !important;\n}\n.errorTextField {\n  border: 1px solid red !important;\n}\n\n.textFieldProfile::placeholder {\n  font-size: 0.91rem;\n  font-weight: 400;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

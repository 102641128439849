import React from "react";
import { Button, Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import LoginHeader from "../../login/components/loginHeader";
import { useStore } from "../../../store/store";
import { useNavigate } from "react-router";
import { useOAuthRegisterApi } from "../../../api/iam/apiHooks";
import { COOKIES_KEYS } from "../../../utils";
import Cookies from "js-cookie";

const SignUpForm = ({ additionalData }) => {
  const authStore = useStore((state) => state.setAuth);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  console.log(errors);
  const { mutate: OAuthRegisterMutation } = useOAuthRegisterApi();

  const onSubmit = (formData) => {
    try {
      const payload = {
        name: formData.name,
        data: additionalData,
      };
      OAuthRegisterMutation(payload, {
        onSuccess: (data) => {
          Cookies.set(COOKIES_KEYS.accessToken, data.access_token);
          authStore(true);
          navigate("/dashboard");
        },
      });
    } catch (error) {
      console.error("Sign-up error:", error);
    }
  };

  return (
    <Grid>
      <LoginHeader signupText="Already have an account?" signinText="Sign In" />
      <Grid className="bgImage">
        <Grid className="cardStyle1">
          <Grid className="cardDesign">
            <Grid className="cardfix ">
              <h3
                style={{
                  fontSize: "32px",
                  letterSpacing: "-.5px",
                  lineHeight: "40px",
                  fontWeight: "700",
                }}
              >
                Complete Registration
              </h3>

              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid className="d-grid gap-3">
                  <Grid>
                    <label className="labelStyle">Full Name</label>
                    <Grid>
                      <input
                        className="inputStyles textfieldDottoedLine"
                        placeholder="Enter a name"
                        {...register("name", {
                          required: "Full name is required",
                        })}
                      />
                      {errors.name && (
                        <span className="text-danger">
                          {errors.name.message}
                        </span>
                      )}
                    </Grid>
                  </Grid>
                  <Grid>
                    <Button
                      color="success"
                      className="SignUpbuttonStyle"
                      type="submit"
                    >
                      Register
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SignUpForm;

import React from "react";
import { Button, Grid, Typography, Box } from "@mui/material";
import LoginHeader from "../../pages/login/components/loginHeader";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useNavigate } from "react-router-dom";

const InfoPage = () => {
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate("/login");
  };

  return (
    <>
      <LoginHeader signupText="Already have an account?" signinText="Sign In" />
      <Grid
        container
        className="bgImage"
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: "100vh" }}
      >
        <Grid className="cardStyle">
          <Grid className="cardDesign">
            <Grid className="cardfix">
              <Box display="flex" flexDirection="column" alignItems="center">
                <ErrorOutlineIcon color="warning" fontSize="large" />
                <Typography variant="h5" gutterBottom mt={2}>
                  You are already registered, please login!
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleLogin}
                  sx={{ mt: 2 }}
                >
                  OK
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default InfoPage;

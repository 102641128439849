// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alertMsgError {
  border-radius: 10px !important;
  border: 1px solid rgb(130, 8, 0) !important;
  background-color: rgb(255, 226, 224) !important;
  color: #031b4e !important;
  margin: 8px 0px;
  font-size: 16px !important;
  font-weight: 400 !important;
  padding: 16px !important;
}

.alertMsgSuccess {
  border-radius: 10px !important;
  border: 1px solid rgb(0, 89, 85) !important;
  background: rgb(212, 239, 238) !important;
  color: #031b4e !important;
  margin: 8px 0px;
  padding: 16px !important;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,2CAA2C;EAC3C,+CAA+C;EAC/C,yBAAyB;EACzB,eAAe;EACf,0BAA0B;EAC1B,2BAA2B;EAC3B,wBAAwB;AAC1B;;AAEA;EACE,8BAA8B;EAC9B,2CAA2C;EAC3C,yCAAyC;EACzC,yBAAyB;EACzB,eAAe;EACf,wBAAwB;AAC1B","sourcesContent":[".alertMsgError {\n  border-radius: 10px !important;\n  border: 1px solid rgb(130, 8, 0) !important;\n  background-color: rgb(255, 226, 224) !important;\n  color: #031b4e !important;\n  margin: 8px 0px;\n  font-size: 16px !important;\n  font-weight: 400 !important;\n  padding: 16px !important;\n}\n\n.alertMsgSuccess {\n  border-radius: 10px !important;\n  border: 1px solid rgb(0, 89, 85) !important;\n  background: rgb(212, 239, 238) !important;\n  color: #031b4e !important;\n  margin: 8px 0px;\n  padding: 16px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

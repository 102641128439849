import React from "react";
import HomeIcon from "@mui/icons-material/Home";
import AddIcon from "@mui/icons-material/Add";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";

export const DASHBOARDELEMENTS = [
  {
    elementId: 1,
    mainHeader: "PROJECTS",
    icon: <HomeIcon />,
    subHeaders: [
      {
        subId: 1,
        subHeader: "Product",
        icon: <SpaceDashboardIcon />,
        path: "/product",
      },
      {
        subId: 2,
        subHeader: "New Project",
        icon: <AddIcon />,
        path: "/newproject",
      },
    ],
  },
  {
    elementId: 2,
    mainHeader: "IAM",
    icon: <HomeIcon />,
    subHeaders: [
      {
        subId: 1,
        subHeader: "Users",
        path: "/users",
      },
      {
        subId: 2,
        subHeader: "Droplets",
        path: "/droplets",
      },
    ],
  },
  {
    elementId: 3,
    mainHeader: "Payments",
    icon: <HomeIcon />,
    subHeaders: [
      {
        subId: 1,
        subHeader: "Services",
        path: "/services",
      },
      {
        subId: 2,
        subHeader: "Api Keys",
        path: "/apiKeys",
      },
      {
        subId: 3,
        subHeader: "Models",
        path: "/models",
      },
    ],
  },
  {
    elementId: 4,
    mainHeader: "AI",
    icon: <HomeIcon />,
    subHeaders: [
      {
        subId: 1,
        subHeader: "Account",
        path: "/account",
      },
      {
        subId: 2,
        subHeader: "Document",
        path: "/document",
      },
    ],
  },
  {
    elementId: 5,
    mainHeader: "Code",
    icon: <HomeIcon />,
    subHeaders: [
      {
        subId: 1,
        subHeader: "Account",
        path: "/account",
      },
      {
        subId: 2,
        subHeader: "Document",
        path: "/document",
      },
    ],
  },
];

export const SETTINGSELEMENTS = [
  {
    id: 1,
    elementName: "Billing",
  },
  {
    id: 2,
    elementName: "Support",
  },
  {
    id: 3,
    elementName: "Settings",
  },
];

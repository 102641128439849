// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Need-help{
    color: #081b4b !important;
    font-size: 20px !important;
    font-weight: 500 !important;
    line-height: 28px !important;
}

.troubleshoot{
    color: rgb(77,91,124) !important;
    text-decoration: underline ;
    text-decoration-color: rgb(77,91,124) !important;
}

.troubleshoot:hover {
    text-decoration: none;
    color: rgb(0, 105, 255) !important;
  }
  
  .verify-button{
    border-radius: 10px !important;
    padding: 1rem 1rem !important;
    border-radius: 10px !important;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s !important;
    line-height: 44px !important;
    height: 48px !important;
    min-width: 40px !important;
    font-weight: 600 !important;
    max-width: none !important;
    font-size: 16px !important;
    text-decoration: none !important;
    text-align: center !important;
    white-space: nowrap !important;
    border-width: 2px !important;
    border-style: solid !important;
    border-color: transparent !important;
    width: 100% !important;
  }`, "",{"version":3,"sources":["webpack://./src/components/OtpVerification/OtpVerificationForm.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,0BAA0B;IAC1B,2BAA2B;IAC3B,4BAA4B;AAChC;;AAEA;IACI,gCAAgC;IAChC,2BAA2B;IAC3B,gDAAgD;AACpD;;AAEA;IACI,qBAAqB;IACrB,kCAAkC;EACpC;;EAEA;IACE,8BAA8B;IAC9B,6BAA6B;IAC7B,8BAA8B;IAC9B,wEAAwE;IACxE,4BAA4B;IAC5B,uBAAuB;IACvB,0BAA0B;IAC1B,2BAA2B;IAC3B,0BAA0B;IAC1B,0BAA0B;IAC1B,gCAAgC;IAChC,6BAA6B;IAC7B,8BAA8B;IAC9B,4BAA4B;IAC5B,8BAA8B;IAC9B,oCAAoC;IACpC,sBAAsB;EACxB","sourcesContent":[".Need-help{\n    color: #081b4b !important;\n    font-size: 20px !important;\n    font-weight: 500 !important;\n    line-height: 28px !important;\n}\n\n.troubleshoot{\n    color: rgb(77,91,124) !important;\n    text-decoration: underline ;\n    text-decoration-color: rgb(77,91,124) !important;\n}\n\n.troubleshoot:hover {\n    text-decoration: none;\n    color: rgb(0, 105, 255) !important;\n  }\n  \n  .verify-button{\n    border-radius: 10px !important;\n    padding: 1rem 1rem !important;\n    border-radius: 10px !important;\n    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s !important;\n    line-height: 44px !important;\n    height: 48px !important;\n    min-width: 40px !important;\n    font-weight: 600 !important;\n    max-width: none !important;\n    font-size: 16px !important;\n    text-decoration: none !important;\n    text-align: center !important;\n    white-space: nowrap !important;\n    border-width: 2px !important;\n    border-style: solid !important;\n    border-color: transparent !important;\n    width: 100% !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from "react";
import "../../pages/login/Login.css";
import { Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const SocialAuthButtons = ({ loginWithSocialMedia, name, imgLogo }) => {
  const theme = useTheme();
  return (
    <div>
      <Button
        sx={{
          "&:hover": {
            backgroundColor: theme.palette.socialAuthButton.light,
            color: theme.palette.socialAuthButton.main,
          },
        }}
        color="socialAuthButton"
        className="link"
        onClick={loginWithSocialMedia}
      >
        <span>
          <img src={imgLogo} className="googleLogo" alt="Google Logo" />
        </span>
        &nbsp; {name}
      </Button>
    </div>
  );
};

export default SocialAuthButtons;

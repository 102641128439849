import { Grid } from "@mui/material";
import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import UsersList from "../users/UsersList";
import Droplets from "../Droplets/Droplets";
import DropletDetail from "../Droplets/DropletDetails";
import { MyAccount, Profile, Activity } from "../myAccount";

const Routing = () => {
  // Define your routes in an array
  const routes = [
    {
      path: "/users",
      element: <UsersList />,
    },
    {
      path: "/droplets",
      element: <Droplets />,
    },
    {
      path: "/droplets/dropletDetails/:id",
      element: <DropletDetail />,
    },
    {
      path: "/myAccount",
      element: <MyAccount />,
      children: [
        { path: "profile", element: <Profile /> },
        { path: "activity", element: <Activity /> },
      ],
    },
    {
      path: "/", // Default path
      element: <Navigate to="/users" replace />, // Redirect to /Users
    },
    {
      path: "*",
      element: <div>404 Not Found</div>, // Optional: Handle unmatched routes
    },
  ];

  // Use useRoutes to render the routes
  const routing = useRoutes(routes);

  return <Grid>{routing}</Grid>;
};

export default Routing;

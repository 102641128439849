// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.textfield{
    padding: 10px;
    width: 40px;
    height: 40px;
    text-align: center;
    border: 1px solid rgb(91, 105, 135);
    border-radius: 3px;
    transition: box-shadow 0.2s ease;
  }
  .textfield:hover{
    border-color: rgb(0, 97, 235) !important;
  }
  
  .textfield:focus {
    box-shadow: 0 0 2px rgba(17, 169, 94, 0.5);
    border-color: rgb(0, 97, 235) ;
    outline: none; /* Remove default outline */
    outline: rgb(91, 105, 135) dotted 2px ;
    outline-offset: 1px ;
    border-radius: 3px ;
    z-index: 2 ;
  }
  
  .iconButton {
    color: rgb(91, 105, 135);
    border-radius: 3px !important;
    padding: 8px;
  }
  .iconButton:focus{
    outline: none;
    outline: rgb(91, 105, 135) dotted 2px  !important;
    outline-offset: 1px !important;
    border-radius: 3px !important;
  }
  
  .iconButton:hover {
    background-color: rgb(243, 245, 249);
  }`, "",{"version":3,"sources":["webpack://./src/components/pagination/Pagination.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,mCAAmC;IACnC,kBAAkB;IAClB,gCAAgC;EAClC;EACA;IACE,wCAAwC;EAC1C;;EAEA;IACE,0CAA0C;IAC1C,8BAA8B;IAC9B,aAAa,EAAE,2BAA2B;IAC1C,sCAAsC;IACtC,oBAAoB;IACpB,mBAAmB;IACnB,WAAW;EACb;;EAEA;IACE,wBAAwB;IACxB,6BAA6B;IAC7B,YAAY;EACd;EACA;IACE,aAAa;IACb,iDAAiD;IACjD,8BAA8B;IAC9B,6BAA6B;EAC/B;;EAEA;IACE,oCAAoC;EACtC","sourcesContent":["\n.textfield{\n    padding: 10px;\n    width: 40px;\n    height: 40px;\n    text-align: center;\n    border: 1px solid rgb(91, 105, 135);\n    border-radius: 3px;\n    transition: box-shadow 0.2s ease;\n  }\n  .textfield:hover{\n    border-color: rgb(0, 97, 235) !important;\n  }\n  \n  .textfield:focus {\n    box-shadow: 0 0 2px rgba(17, 169, 94, 0.5);\n    border-color: rgb(0, 97, 235) ;\n    outline: none; /* Remove default outline */\n    outline: rgb(91, 105, 135) dotted 2px ;\n    outline-offset: 1px ;\n    border-radius: 3px ;\n    z-index: 2 ;\n  }\n  \n  .iconButton {\n    color: rgb(91, 105, 135);\n    border-radius: 3px !important;\n    padding: 8px;\n  }\n  .iconButton:focus{\n    outline: none;\n    outline: rgb(91, 105, 135) dotted 2px  !important;\n    outline-offset: 1px !important;\n    border-radius: 3px !important;\n  }\n  \n  .iconButton:hover {\n    background-color: rgb(243, 245, 249);\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

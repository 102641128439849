// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.imgAvatarstyles1 {
  flex: 0 0 auto;
  position: relative;
  font-size: 14px;
  background-clip: padding-box;
  background-color: rgb(229, 232, 237);
  border-radius: 50%;
  width: 95px;
  height: 95px;
  bottom: 1.2rem;
}
.imgAvatarStyles {
  border: 0;
  height: auto;
  max-width: 100%;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: none;
}
.containerStyle {
  padding-right: 7rem !important;
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}
`, "",{"version":3,"sources":["webpack://./src/pages/myAccount/MyAccount.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,4BAA4B;EAC5B,oCAAoC;EACpC,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,cAAc;AAChB;AACA;EACE,SAAS;EACT,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,8BAA8B;EAC9B,2BAA2B;EAC3B,8BAA8B;AAChC","sourcesContent":[".imgAvatarstyles1 {\n  flex: 0 0 auto;\n  position: relative;\n  font-size: 14px;\n  background-clip: padding-box;\n  background-color: rgb(229, 232, 237);\n  border-radius: 50%;\n  width: 95px;\n  height: 95px;\n  bottom: 1.2rem;\n}\n.imgAvatarStyles {\n  border: 0;\n  height: auto;\n  max-width: 100%;\n  overflow: hidden;\n  border-radius: 50%;\n  box-shadow: none;\n}\n.containerStyle {\n  padding-right: 7rem !important;\n  margin-top: 5rem !important;\n  margin-bottom: 5rem !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

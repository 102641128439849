import React from "react";
import "./CustomInput.css";
import { Grid } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";

export const CustomInput = ({
  value,
  focusValid,
  labelStyle,
  labeltext,
  labelMandatory = false,
  labelMandatoryStyle,
  gridclassName,
  error,
  errorStyle,
  suffixIcon,
  inputStyle,
  inputDivStyle,
  ...rest
}) => {
  return (
    <>
      <Grid>
        <label
          style={{
            fontWeight: 600,
            color: "031b4e",
            fontSize: ".875rem",
            ...labelStyle,
          }}
        >
          {labeltext}{" "}
          {labelMandatory && <span style={labelMandatoryStyle}>*</span>}
        </label>
      </Grid>
      <Grid className={gridclassName}>
        <Grid
          className="input-main-div"
          sx={{
            borderStyle: focusValid && value === "" ? "" : "solid",
            ...inputDivStyle,
          }}
        >
          <input style={{ marginLeft: "1.2rem", ...inputStyle }} {...rest} />
          {suffixIcon && suffixIcon}
        </Grid>
      </Grid>
      {error && (
        <Grid
          className="mt-2"
          sx={{
            color: "rgb(202, 12, 12)",
            fontSize: "14px",
            fontWeight: 600,
            ...errorStyle,
          }}
        >
          <ErrorIcon />
          {error}
        </Grid>
      )}
    </>
  );
};

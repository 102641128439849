import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import "./MyAccount.css";
import Header from "./components/header/Header";
import AccountTab from "./components/accountTab/AccountTab";
import { useMyAccountProfile } from "../../api/iam/apiHooks";
import Loader from "../dashBoard/components/loader/Loader";
import { useStore } from "../../store/store";

export const MyAccount = () => {
  const isSetProfileDetails = useStore((state) => state.setProfileDetails);
  const isprofileDetails = useStore((state) => state.profileDetails);

  const {
    isLoading,
    data: myAccountData,
    isError,
    error,
  } = useMyAccountProfile();
  useEffect(() => {
    if (myAccountData !== undefined) {
      isSetProfileDetails(myAccountData);
    }
  }, [myAccountData, isSetProfileDetails]);

  if (isError && myAccountData === undefined) {
    return (
      <Grid>{isError && <b className="text-danger">{error.message}</b>}</Grid>
    );
  }
  console.log(isprofileDetails.joined_date, "isprofileDetails.joined_date");
  return (
    <>
      {isLoading ? (
        <>
          <Loader />;
        </>
      ) : (
        <>
          <Grid>
            <Header />
            <Grid className="container containerStyle">
              <Grid className="row">
                <Grid className="d-flex gap-3">
                  <Grid>
                    <div className="imgAvatarstyles1 mt-2">
                      <img
                        alt="Avatar"
                        src={
                          "https://i0.wp.com/cloud.digitalocean.com/avatars/default43.png?ssl=1"
                        }
                        className="imgAvatarStyles"
                      />
                    </div>
                  </Grid>
                  <Grid>
                    <h2>{isprofileDetails.name}</h2>
                    <Grid>
                      Member since{" "}
                      {new Date(
                        isprofileDetails.joined_date !== undefined
                          ? isprofileDetails.joined_date
                          : new Date(),
                      ).toLocaleDateString("en-US")}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid className="mt-3">
                <AccountTab />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

import {
  Grid,
  Paper,
  Table,
  Button,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { Fragment, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import UserItem from "./components/UserItem";
import "./UsersList.css";
import Loader from "../dashBoard/components/loader/Loader";
import Pagination from "../../components/pagination/Pagination";
import { useUsersList, useOrganizationList } from "../../api/iam/apiHooks";
import UserInviteModal from "./components/userInviteModal/UserInviteModal";

const UsersList = () => {
  const [pageSize, setPageSize] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const { data: organizationListData } = useOrganizationList();

  const {
    isLoading,
    data: usersListData,
    isError,
    error,
    isFetching,
  } = useUsersList(pageNumber, pageSize);

  if (isError && usersListData === undefined) {
    return (
      <Grid>{isError && <b className="text-danger">{error.message}</b>}</Grid>
    );
  }
  return (
    <>
      <Grid className="container">
        {isLoading ? (
          <>
            <Loader />;
          </>
        ) : (
          <>
            <Grid>
              <b>{usersListData.data.length} Members</b>
            </Grid>
            <Grid className=" mt-3 d-flex justify-content-between">
              <Grid className="col-md-3">
                <Grid className="textfieldDottoedLine mainDiv  ">
                  <Grid className="iconsStyles">
                    <SearchIcon sx={{ color: "#8390af" }} />
                  </Grid>
                  <input
                    type="text"
                    placeholder="Search by name"
                    className="inputStyles1"
                    disabled={!usersListData.data.length > 0}
                  />
                </Grid>
              </Grid>
              <Grid className="d-flex ">
                <Button
                  sx={{ display: { xs: "none", sm: "block" } }}
                  className="createDroplet_button"
                >
                  <span onClick={handleOpenModal}>Invite Users</span>
                </Button>
              </Grid>
            </Grid>
            <TableContainer
              component={Paper}
              className="mt-4"
              // style={{ maxHeight: 400 }}
            >
              <Table>
                <TableHead
                  className="tableHeader"
                  sx={{
                    "& th": {
                      height: "20px", // Set the height for each header cell
                      padding: "6px", // Adjust padding if needed
                    },
                    backgroundColor: "rgb(243, 245, 249)",
                  }}
                >
                  <TableRow>
                    <TableCell className="headerFont px-3">Name</TableCell>
                    <TableCell className="headerFont px-3">Role</TableCell>
                    <TableCell className="headerFont px-3">Status</TableCell>
                    <TableCell align="right" className="headerFont">
                      <span style={{ paddingRight: "2.8rem" }}></span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {usersListData.data.length > 0 ? (
                    usersListData.data.map((resp, index) => (
                      <Fragment key={index}>
                        <UserItem resp={resp} />
                      </Fragment>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell>NA</TableCell>
                      <TableCell>NA</TableCell>
                      <TableCell>NA</TableCell>
                      <TableCell
                        align="right"
                        style={{ paddingRight: "2.5rem" }}
                      >
                        NA
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid className="d-flex m-3 justify-content-end">
              <Pagination
                totalRecords={usersListData.totalRecords}
                pageSize={pageSize}
                Fetching={isFetching}
                setPageSize={setPageSize}
                setPageNumber={setPageNumber}
                pageNumber={pageNumber}
              />
            </Grid>
          </>
        )}
      </Grid>

      <UserInviteModal
        organizationListData={organizationListData}
        open={isModalOpen}
        onClose={handleCloseModal}
      />
    </>
  );
};

export default UsersList;

import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import logo from "../../../../assets/enfeclogo.png";
import { Link, useNavigate } from "react-router-dom";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import "./Header.css";
import Cookies from "js-cookie";
import Tooltip from "@mui/material/Tooltip";
import { useStore } from "../../../../store/store";
import { COOKIES_KEYS } from "../../../../utils";
import { useLogoutApi } from "../../../../api/iam/apiHooks";

const Header = () => {
  const authStore = useStore((state) => state.setAuth);
  let navigate = useNavigate();
  const { mutate: logoutMutation } = useLogoutApi();

  const handleLogout = () => {
    logoutMutation(
      { loggedOut: true },
      {
        onSuccess: () => {
          authStore(false);
          navigate("/");
          // Remove cookies
          Cookies.remove(COOKIES_KEYS.email);
          Cookies.remove(COOKIES_KEYS.accessToken);
          Cookies.remove(COOKIES_KEYS.activationId);
        },
        onError: (error) => {
          console.error("Logout error:", error);
        },
      },
    );
  };
  return (
    <>
      <div
        className="navBar"
        style={{ boxShadow: "1px 2px 4px 0 rgba(0, 0, 0, 0.03)" }}
      >
        <Box className="d-flex justify-content-center align-items-center">
          <Link to="/users" style={{ textDecoration: "none" }}>
            <img
              src={logo}
              className="rounded-circle header-logo"
              alt="enfeclogo"
            />
          </Link>
          <Typography className="px-2" sx={{ fontWeight: 600 }}>
            My Account
          </Typography>
          <Link to="/Users" className="controlPanel px-3">
            Back to Control Panel
          </Link>
        </Box>
        <Typography sx={{ fontSize: "14px" }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Tooltip title="Support" arrow>
              <IconButton
                edge="end"
                color="#808080"
                aria-label="info"
                sx={{ pl: 1 }}
              >
                <HelpOutlineOutlinedIcon className="HelpOutIcon" />
              </IconButton>
            </Tooltip>
            <span className="top-nav-partition"></span>
            <Link onClick={handleLogout} className="logout">
              Sign Out
            </Link>
          </Box>
        </Typography>
      </div>
    </>
  );
};

export default Header;

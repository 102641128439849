import React from "react";
import { TableCell, TableRow, Typography } from "@mui/material";
import "../UsersList.css";
import { Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useUsersStatus } from "../../../api/iam/apiHooks";
import { toast } from "react-toastify";

const UserItem = ({ resp }) => {
  const { id, blocked_status } = resp;

  const { isLoading, mutate: usersStatus } = useUsersStatus();

  const handleUserStatus = (userId, status) => {
    const payload = {
      id: userId,
      status: status,
    };
    usersStatus(payload, {
      onSuccess: (body) => {
        resp.blocked_status = !blocked_status;
        toast.success(body.message);
      },
      onError: (error) => {
        toast.error(`Failed to update status: ${error.message}`);
      },
    });
  };

  return (
    <TableRow
      sx={{
        backgroundColor: blocked_status ? "inherit" : "rgb(243, 245, 249)",
        color: blocked_status ? "inherit" : "#a0a0a0",
      }}
    >
      <TableCell sx={{ width: "25rem" }}>
        <div className="d-flex gap-1">
          <div className="imgstyles1 mt-2">
            <img alt="Avatar" src={resp.avatar} className="imgStyles" />
          </div>
          <div>
            <div className="tablecellStyles">
              {resp.name}
              {resp.logged && (
                <span className="px-1 tablecellStyles">(you)</span>
              )}
            </div>
            <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
              {resp.email}
            </Typography>
          </div>
        </div>
      </TableCell>
      <TableCell>
        <div className="dotterstyle">{resp.role}</div>
      </TableCell>
      <TableCell>{resp.status}</TableCell>

      <TableCell
        align="right"
        className="px-2"
        sx={{ position: "relative", width: "100px" }}
      >
        {isLoading ? (
          <CircularProgress size={20} className="circularProgress" />
        ) : (
          <Button
            className={blocked_status ? "unblock_button" : "block_button"}
            onClick={() =>
              handleUserStatus(id, blocked_status ? "unblock" : "block")
            }
          >
            {blocked_status ? "Unblock" : "Block"}
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
};

export default UserItem;

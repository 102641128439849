/* eslint-disable react/react-in-jsx-scope */
import Link from "@mui/material/Link";
import "./Footer.css";
import { FOOTERELEMENTS } from "../../data/footerElements";

const Footer = () => {
  return (
    <footer className="footer">
      <ul className="d-flex ulItems">
        {FOOTERELEMENTS.map((resp, index) => (
          <li key={index} className=" listyles">
            <Link
              key={index}
              variant="body2"
              color="inherit"
              className="footerElement"
            >
              {resp.footer}
            </Link>
          </li>
        ))}
      </ul>
    </footer>
  );
};

export default Footer;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.password-dotter-line:hover {
  border: 1px solid red !important;
}
.password-dotter-line:focus-within {
  outline: rgb(91, 105, 135) dotted 2px !important;
  outline-offset: 1px !important;
  border-radius: none !important;
  z-index: 2 !important;
}
.password-ProfileDottoedLine:hover {
  border-color: rgb(0, 97, 235) !important;
}

.password-errorTextField {
  border: 1px solid red !important;
}
`, "",{"version":3,"sources":["webpack://./src/pages/myAccount/components/password/Password.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;AAClC;AACA;EACE,gDAAgD;EAChD,8BAA8B;EAC9B,8BAA8B;EAC9B,qBAAqB;AACvB;AACA;EACE,wCAAwC;AAC1C;;AAEA;EACE,gCAAgC;AAClC","sourcesContent":[".password-dotter-line:hover {\n  border: 1px solid red !important;\n}\n.password-dotter-line:focus-within {\n  outline: rgb(91, 105, 135) dotted 2px !important;\n  outline-offset: 1px !important;\n  border-radius: none !important;\n  z-index: 2 !important;\n}\n.password-ProfileDottoedLine:hover {\n  border-color: rgb(0, 97, 235) !important;\n}\n\n.password-errorTextField {\n  border: 1px solid red !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

/* eslint-disable react/no-unknown-property */
import React, { useState } from "react";
import { Typography, Paper, Grid, Button } from "@mui/material";
import LoginHeader from "./components/loginHeader";
import "./Login.css";
import { useForm } from "react-hook-form";
import { ERRORMESSAGES, validateEmail, COOKIES_KEYS } from "../../utils";
import { Link, useNavigate } from "react-router-dom";
import SocialAuthButtons from "../../components/commonButtons/SocialAuthButtons";
import OtpVerificationForm from "../../components/OtpVerification/OtpVerificationForm";
import GoogleLogo from "../../assets/google-icon.png";
import GithubLogo from "../../assets/github-icon.png";
import { FormError, FormSuccess, FormLoader } from "../../components/form";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";
import Loader from "../dashBoard/components/loader/Loader";
import { useSigninApi, useTwoFactorAuthApi } from "../../api/iam/apiHooks";
import { useStore } from "../../store/store";
import { useErrorMessage } from "../../hooks/useErrorMessage";
import useSocialAuth from "../../hooks/useSocialAuth";

const LoginPage = () => {
  const location = useLocation();
  const [isOtpPage, setIsOtpPage] = useState(false);
  const authStore = useStore((state) => state.setAuth);
  let activationId = Cookies.get(COOKIES_KEYS.activationId);
  let client_id = Cookies.get(COOKIES_KEYS.uniqueId);
  let navigate = useNavigate();
  const { loginWithSocialMedia, isFetching } = useSocialAuth();
  const { error, handleError } = useErrorMessage();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const {
    mutate: loginMutation,
    isSuccess,
    isLoading,
    isError,
  } = useSigninApi();

  const { mutate: twoFactorAuthentication, ...rest } = useTwoFactorAuthApi();

  const onSubmit = (data) => {
    Cookies.set(COOKIES_KEYS.email, data.email);
    let payload = {
      email: data.email,
      password: data.password,
      client_id: client_id,
    };
    loginMutation(payload, {
      onSuccess: (response) => {
        if (response.status === 200) {
          // User logged in successfully without 2FA
          Cookies.set(COOKIES_KEYS.accessToken, response.data.access_token);
          authStore(true);
          navigate("/users");
        } else if (response.status === 202) {
          // User needs to complete 2FA
          const { activation_id } = response.data;
          Cookies.set(COOKIES_KEYS.activationId, activation_id);
          setIsOtpPage(true);
        }
      },
      onError: (error) => {
        handleError(error);
      },
    });
  };

  const handleOtpSubmit = (data, onSuccess, onError) => {
    let payload = {
      activation_id: activationId,
      activation_code: data.otp,
      client_id: client_id,
      expiry_option: "transactional",
    };

    twoFactorAuthentication(payload, {
      onSuccess: onSuccess,
      onError: onError,
    });
  };

  return (
    <>
      {isFetching ? (
        <Loader />
      ) : (
        <div>
          <LoginHeader
            signupText="Don't have an account?"
            signinText="Sign Up"
          />
          <div style={{ background: "rgb(243, 245, 249)", minHeight: "100vh" }}>
            <div className="login-container1">
              <Paper
                elevation={10}
                className="paperStyle "
                sx={{
                  boxShadow: "rgba(11, 43, 158, 0.15) 0px 6px 20px -6px",
                  marginTop: { xs: "110px", lg: "145px" }, // Adjust the margin as needed
                }}
              >
                {isOtpPage ? (
                  <OtpVerificationForm
                    onSubmit={handleOtpSubmit}
                    {...rest}
                    context="login"
                  />
                ) : (
                  <div>
                    <Grid>
                      {location.state?.message && (
                        <FormSuccess
                          isSuccess={true}
                          SuccessMessage={{
                            response: { data: location.state.message },
                          }}
                        />
                      )}
                      <Typography className="heading">
                        {" "}
                        Log in to your account
                      </Typography>
                    </Grid>

                    <Grid>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <FormError isError={isError} error={error} />
                        <FormSuccess isSuccess={isSuccess} error={error} />
                        <Typography className="label label-required mt-3">
                          email
                        </Typography>
                        <input
                          className="textField textfieldDottoedLine"
                          name="email"
                          type="text"
                          placeholder="Enter your email address"
                          {...register("email", {
                            required: ERRORMESSAGES.loginPageErrormsg.email,
                            validate: {
                              validEmail: (value) =>
                                validateEmail(value) ||
                                ERRORMESSAGES.loginPageErrormsg.validEmail,
                            },
                          })}
                        />
                        {errors.email && (
                          <span className="text-danger">
                            {errors.email.message}
                          </span>
                        )}
                        <Typography className="label label-required mt-3">
                          password
                        </Typography>
                        <Grid>
                          <input
                            className="textField textfieldDottoedLine"
                            name="password"
                            type={"password"}
                            placeholder="Enter your password"
                            {...register("password", {
                              required:
                                ERRORMESSAGES.loginPageErrormsg.password,
                            })}
                          />
                        </Grid>
                        <span className="text-danger">
                          {errors.password && (
                            <span className="text-danger">
                              {errors.password.message}
                            </span>
                          )}
                        </span>
                        <Button className="login-button" type="submit">
                          Log In
                        </Button>
                        <FormLoader
                          isLoading={isLoading}
                          message="Signing In"
                        />
                      </form>
                    </Grid>
                    <Grid className="line"></Grid>
                    <SocialAuthButtons
                      loginWithSocialMedia={() =>
                        loginWithSocialMedia("google", "login")
                      }
                      name="Sign In with Google"
                      imgLogo={GoogleLogo}
                    />
                    <SocialAuthButtons
                      loginWithSocialMedia={() =>
                        loginWithSocialMedia("github", "login")
                      }
                      name="Sign In with GitHub"
                      imgLogo={GithubLogo}
                    />
                    <Grid className="py-3">
                      <Link to={"/forgotPassword"} className="forgotPassword">
                        Forgot Password?
                      </Link>
                    </Grid>
                  </div>
                )}
              </Paper>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LoginPage;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.top-nav-partition {
  border: 1px solid #ececec;
  position: relative;
  top: 8px;
  height: 39px;
  margin: 0 0 15px 0;
}
.controlPanel {
  font-weight: 600;
  color: rgb(91, 105, 135);
  border-color: rgb(91, 105, 135);
  text-decoration: none;
  background: transparent;
}

.controlPanel:hover {
  color: rgb(131, 144, 175);
}

.logout {
  text-decoration: none;
  color: red;
  font-weight: 600;
}
.logout:hover {
  color: rgb(244, 72, 72);
}
`, "",{"version":3,"sources":["webpack://./src/pages/myAccount/components/header/Header.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,QAAQ;EACR,YAAY;EACZ,kBAAkB;AACpB;AACA;EACE,gBAAgB;EAChB,wBAAwB;EACxB,+BAA+B;EAC/B,qBAAqB;EACrB,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,qBAAqB;EACrB,UAAU;EACV,gBAAgB;AAClB;AACA;EACE,uBAAuB;AACzB","sourcesContent":[".top-nav-partition {\n  border: 1px solid #ececec;\n  position: relative;\n  top: 8px;\n  height: 39px;\n  margin: 0 0 15px 0;\n}\n.controlPanel {\n  font-weight: 600;\n  color: rgb(91, 105, 135);\n  border-color: rgb(91, 105, 135);\n  text-decoration: none;\n  background: transparent;\n}\n\n.controlPanel:hover {\n  color: rgb(131, 144, 175);\n}\n\n.logout {\n  text-decoration: none;\n  color: red;\n  font-weight: 600;\n}\n.logout:hover {\n  color: rgb(244, 72, 72);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

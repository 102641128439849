import React, { useState } from "react";
import "./SignUp.css";
import { Button, Grid, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import LoginHeader from "../login/components/loginHeader";
import {
  ERRORMESSAGES,
  validateEmail,
  COOKIES_KEYS,
  // validatePassword,
} from "../../utils";
import { Link } from "react-router-dom";
import OtpVerificationForm from "../../components/OtpVerification/OtpVerificationForm";
import Cookies from "js-cookie";
import { FormLoader, FormError } from "../../components/form";
import { usePasswordActivationApi, useSignUpApi } from "../../api/iam/apiHooks";
import { useErrorMessage } from "../../hooks/useErrorMessage";

const SignUp = () => {
  const [isOtpPage, setIsOtpPage] = useState(false); // State to track which form to show
  let activationId = Cookies.get(COOKIES_KEYS.activationId);

  const { error, handleError } = useErrorMessage();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { mutateAsync: signUpMutate, isLoading, isError } = useSignUpApi();

  const onSubmit = async (formData) => {
    let payload = {
      email: formData.email,
      // password: formData.password,
      name: formData.name,
    };
    Cookies.set(COOKIES_KEYS.email, formData.email);
    try {
      const signUpResponse = await signUpMutate(payload);
      Cookies.set(COOKIES_KEYS.activationId, signUpResponse.activation_id);
      setIsOtpPage(true);
    } catch (error) {
      handleError(error);
    }
  };

  const { mutate: passwordActivationMutate, ...rest } =
    usePasswordActivationApi();

  const handleOtpSubmit = (data, onSuccess, onError) => {
    let payload = {
      activation_id: activationId,
      activation_code: data.otp,
    };

    passwordActivationMutate(payload, {
      onSuccess: onSuccess,
      onError: onError,
    });
  };
  return (
    <Grid>
      <LoginHeader signupText="Already have an account?" signinText="Sign In" />
      <Grid className="bgImage">
        <Grid className="cardStyle1">
          <Grid className="cardDesign">
            {isOtpPage ? (
              <>
                <OtpVerificationForm
                  onSubmit={handleOtpSubmit}
                  {...rest}
                  context="signup"
                />
              </>
            ) : (
              <Grid className="cardfix ">
                <h3
                  style={{
                    fontSize: "32px",
                    letterSpacing: "-.5px",
                    lineHeight: "40px",
                    fontWeight: "700",
                  }}
                >
                  Sign Up with Email
                </h3>
                <p className="py-1" style={{ color: "rgb(77, 91, 124)" }}>
                  Sign up with &nbsp;
                  <Link
                    to=""
                    sx={{
                      cursor: "pointer",
                      "&:hover": { textDecoration: "none" },
                    }}
                  >
                    Google
                  </Link>
                  &nbsp;or &nbsp;
                  <Link
                    to=""
                    sx={{
                      cursor: "pointer",
                      "&:hover": { textDecoration: "none" },
                    }}
                  >
                    GitHub
                  </Link>
                  &nbsp;instead
                </p>

                <form onSubmit={handleSubmit(onSubmit)}>
                  {isError ? (
                    <FormError isError={isError} error={error} />
                  ) : null}
                  {/* <FormSuccess isSuccess={isSuccess} error={error} /> */}
                  <Grid className="d-grid gap-3">
                    <Grid>
                      <label className="labelStyle">Full Name</label>
                      <Grid>
                        <input
                          className="inputStyles textfieldDottoedLine"
                          placeholder="Enter a name"
                          {...register("name", {
                            required: false,
                          })}
                        />
                      </Grid>
                    </Grid>
                    <Grid>
                      <label className="labelStyle">
                        email address
                        <span className="text-danger" style={{ font: "1rem" }}>
                          *
                        </span>
                      </label>
                      <Grid>
                        <input
                          className="inputStyles textfieldDottoedLine"
                          placeholder="Enter your email address"
                          {...register("email", {
                            required: ERRORMESSAGES.loginPageErrormsg.email,
                            validate: {
                              validEmail: (value) =>
                                validateEmail(value) ||
                                ERRORMESSAGES.loginPageErrormsg.validEmail,
                            },
                          })}
                        />
                        {errors.email && (
                          <span className="text-danger">
                            {errors.email.message}
                          </span>
                        )}
                      </Grid>
                    </Grid>
                    <Grid>
                      <Button
                        color="success"
                        className="SignUpbuttonStyle"
                        type="submit"
                      >
                        Sign Up
                      </Button>
                      {isLoading ? (
                        <FormLoader
                          isLoading={isLoading}
                          message="Creating Account"
                        />
                      ) : null}
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            )}
          </Grid>
          <Grid
            className="d-flex justify-content-center"
            sx={{ position: "relative", top: "1.5rem" }}
          >
            <Typography sx={{ fontSize: "14px" }}>
              By signing up you agree to the &nbsp;
              <Link
                to=""
                className="linkHover"
                sx={{
                  cursor: "pointer",
                  color: "inherit",
                }}
              >
                Terms of Service
              </Link>
              &nbsp;and &nbsp;
              <Link
                to=""
                className="linkHover"
                sx={{ cursor: "pointer", color: "inherit" }}
              >
                Privacy Policy
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SignUp;

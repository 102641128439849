import React, { useState } from "react";
import { Grid, Typography, IconButton } from "@mui/material";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { CircularProgress } from "@mui/material";
import "./Pagination.css";

const GlobalPagination = ({
  totalRecords,
  setPageNumber,
  Fetching,
  pageSize,
  pageNumber,
}) => {
  const totalPages = Math.ceil(totalRecords / pageSize);

  // Local state for the input value
  const [inputValue, setInputValue] = useState(pageNumber);

  const handlePageChange = (direction) => {
    let newPageNumber = pageNumber;

    if (direction === "next" && pageNumber < totalPages) {
      newPageNumber += 1;
    } else if (direction === "prev" && pageNumber > 1) {
      newPageNumber -= 1;
    }

    setPageNumber(newPageNumber);
    setInputValue(newPageNumber);
  };

  const handlePageNumberChange = (event) => {
    event.preventDefault();
    const pageNum = Math.max(
      1,
      Math.min(parseInt(inputValue, 10) || 1, totalPages),
    );
    setPageNumber(pageNum);
    setInputValue(pageNum);
  };

  return (
    <Grid container alignItems="center" justifyContent="center" spacing={2}>
      <Grid item>
        <IconButton
          className="iconButton"
          onClick={() => handlePageChange("prev")}
          disabled={pageNumber === 1}
          disableRipple
        >
          <ChevronLeft />
        </IconButton>
      </Grid>
      <Grid item>
        <Typography
          variant="body1"
          sx={{ display: "flex", alignItems: "center" }}
        >
          <span style={{ marginRight: "0.5rem" }}>Page</span>
          <form onSubmit={handlePageNumberChange}>
            <input
              className="textfield"
              name="pageNumber"
              type="text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
          </form>
          <span style={{ margin: "0 0.5rem" }}>of</span>
          {Fetching ? (
            <CircularProgress size={18} />
          ) : (
            <span style={{ marginLeft: "0.5rem" }}>{totalPages}</span>
          )}
        </Typography>
      </Grid>
      <Grid item>
        <IconButton
          onClick={() => handlePageChange("next")}
          disabled={pageNumber === totalPages}
          disableRipple
          className="iconButton"
        >
          <ChevronRight />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default GlobalPagination;

import React from "react";
import { useParams, Link } from "react-router-dom";
import { Typography, Grid, Button } from "@mui/material";

const DropletDetail = () => {
  const { id } = useParams(); // Get the droplet ID from the URL

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4">Droplet Details</Typography>
        <Typography variant="h6">Details for Droplet ID: {id}</Typography>
        {/* Button to navigate to settings */}
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to={`/droplets`}
        >
          Go to Droplets
        </Button>
      </Grid>
    </Grid>
  );
};

export default DropletDetail;

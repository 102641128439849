import React, { useState } from "react";
import { Button, Grid } from "@mui/material";
import "./ForgotPassword.css";
import LoginHeader from "../login/components/loginHeader";
import { useForm } from "react-hook-form";
import { ERRORMESSAGES, validateEmail } from "../../utils";
import { FormLoader, FormError } from "../../components/form";
import { useRestPassword } from "../../api/iam/apiHooks";
import { COOKIES_KEYS } from "../../utils";
import OtpVerificationForm from "../../components/OtpVerification/OtpVerificationForm";
import Cookies from "js-cookie";
import { usePasswordActivationApi } from "../../api/iam/apiHooks";
import { useErrorMessage } from "../../hooks/useErrorMessage";
function ForgotPassword() {
  let activationId = Cookies.get(COOKIES_KEYS.activationId);
  const [isOtpPage, setIsOtpPage] = useState(false);
  const { error, handleError } = useErrorMessage();
  const { mutate: passwordActivationMutate, ...rest } =
    usePasswordActivationApi();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const {
    mutate: forgotPasswordMutation,
    isLoading,
    isError,
  } = useRestPassword();

  const onSubmit = (data) => {
    let payload = {
      email: data.email,
    };

    forgotPasswordMutation(payload, {
      onSuccess: (body) => {
        Cookies.set(COOKIES_KEYS.activationId, body.activation_id);
        setIsOtpPage(true);
      },
      onError: (error) => {
        handleError(error);
      },
    });
  };

  const handleOtpSubmit = (data, onSuccess, onError) => {
    let payload = {
      activation_id: activationId,
      activation_code: data.otp,
    };
    passwordActivationMutate(payload, {
      onSuccess: onSuccess,
      onError: onError,
    });
  };
  return (
    <Grid>
      <LoginHeader signupText="Don't have an account?" signinText="Sign Up" />
      <Grid className="bgImage">
        <Grid className="cardStyle1">
          <Grid className="cardDesign">
            {isOtpPage ? (
              <>
                <OtpVerificationForm
                  onSubmit={handleOtpSubmit}
                  {...rest}
                  context="signup"
                />
              </>
            ) : (
              <Grid className="cardfix ">
                <h3
                  style={{
                    fontSize: "32px",
                    letterSpacing: "-.5px",
                    lineHeight: "40px",
                    fontWeight: "700",
                  }}
                >
                  Forgot Password?
                </h3>
                <p style={{ color: "rgb(77, 91, 124)" }}>
                  Enter the email address associated with your account and we
                  will send you a link to reset your password.
                </p>
                <FormError isError={isError} error={error} />
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid className="d-grid gap-3">
                    <Grid>
                      <label className="labelStyle">
                        email address
                        <span className="text-danger" style={{ font: "1rem" }}>
                          *
                        </span>
                      </label>
                      <Grid>
                        <input
                          className="inputStyles textfieldDottoedLine"
                          placeholder="Enter your email address"
                          {...register("email", {
                            required: ERRORMESSAGES.loginPageErrormsg.email,
                            validate: {
                              validEmail: (value) =>
                                validateEmail(value) ||
                                ERRORMESSAGES.loginPageErrormsg.validEmail,
                            },
                          })}
                        />
                        {errors.email && (
                          <span className="text-danger">
                            {errors.email.message}
                          </span>
                        )}
                      </Grid>
                    </Grid>

                    <Grid>
                      <Button
                        className="buttonStyle"
                        type="submit"
                        disabled={isLoading}
                      >
                        Request Password Reset
                      </Button>
                      <FormLoader
                        isLoading={isLoading}
                        message="Emailing you a link"
                      />
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ForgotPassword;

import { useMutation, useQuery } from "react-query";
import {
  signInFunction,
  userActivationFunction,
  signUpFunction,
  getUsersData,
  resetPasswordFunction,
  signInWithGitHub,
  signInWithGoogle,
  getInviteUsersData,
  twoFactorAuthFunction,
  logoutFunction,
  getMyAccountProfile,
  passwordUpdateFunction,
  updateMyAccountProfile,
  getInviteSelectedUsers,
  usersStatus,
  getOrganizationData,
  getOAuthUrl,
  redirectUrl,
  OAuthRegister,
  passwordActivationFunction,
  passwordResetFunction,
} from "./apiFunctions";

export const useSignUpApi = () => {
  const mutation = useMutation((body) => signUpFunction(body));
  return { ...mutation };
};

export const useSigninApi = () => {
  const mutation = useMutation((body) => signInFunction(body));
  return { ...mutation };
};
export const useLogoutApi = () => {
  const mutation = useMutation((body) => logoutFunction(body));
  return { ...mutation };
};
export const useUserActivattionApi = () => {
  const mutation = useMutation((body) => userActivationFunction(body));
  return { ...mutation };
};
export const usePasswordActivationApi = () => {
  const mutation = useMutation((body) => passwordActivationFunction(body));
  return { ...mutation };
};
export const usePasswordResetApi = () => {
  const mutation = useMutation((body) => passwordResetFunction(body));
  return { ...mutation };
};
export const useTwoFactorAuthApi = () => {
  const mutation = useMutation((body) => twoFactorAuthFunction(body));
  return { ...mutation };
};
export const useSigninWithGoogleApi = () => {
  const mutation = useMutation((body) => signInWithGoogle(body));
  return { ...mutation };
};
export const useOAuthUrlApi = () => {
  const mutation = useMutation((body) => getOAuthUrl(body));
  return { ...mutation };
};
export const useOAuthRegisterApi = () => {
  const mutation = useMutation((body) => OAuthRegister(body));
  return { ...mutation };
};
export const useRedirectUrlApi = (queryString) => {
  return useQuery({
    queryKey: ["redirect-url", queryString],
    queryFn: redirectUrl,
    keepPreviousData: true,
    staleTime: 1000 * 60 * 5, // Set stale time to 5 minutes (300000 milliseconds)
    retry: 1,
    refetchOnWindowFocus: false,
  });
};
export const useSigninWithGitHubApi = () => {
  const mutation = useMutation((body) => signInWithGitHub(body));
  return { ...mutation };
};
export const useRestPassword = () => {
  const mutation = useMutation((body) => resetPasswordFunction(body));
  return { ...mutation };
};

export const useUsersList = (pageNumber, pageSize) => {
  return useQuery({
    queryKey: ["users-list", { pageNumber, pageSize }],
    queryFn: getUsersData,

    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: 1,
    staleTime: 1000 * 60 * 5, // Set stale time to 5 minutes (300000 milliseconds)
  });
};
export const useOrganizationList = () => {
  return useQuery({
    queryKey: ["Organization-list"],
    queryFn: getOrganizationData,
    keepPreviousData: true,

    refetchOnWindowFocus: false,
    retry: 1,
    staleTime: 1000 * 60 * 5, // Set stale time to 5 minutes (300000 milliseconds)
  });
};
export const useUsersInviteList = () => {
  const mutation = useMutation((body) => getInviteUsersData(body));
  return { ...mutation };
};
export const useInviteSelectedUsers = () => {
  const mutation = useMutation(({ email, id }) =>
    getInviteSelectedUsers({ email }, id),
  );

  return { ...mutation };
};
export const useMyAccountProfile = () => {
  return useQuery({
    queryKey: ["my-accountProfile"],
    queryFn: getMyAccountProfile,
    //keepPreviousData: true,
    //staleTime: 1000 * 60 * 5, // Set stale time to 5 minutes (300000 milliseconds)
  });
};

export const usePasswordUpdateApi = () => {
  const mutation = useMutation((body) => passwordUpdateFunction(body));
  return { ...mutation };
};

export const useUpdateMyAccountProfile = () => {
  const mutation = useMutation((body) => updateMyAccountProfile(body));
  return { ...mutation };
};
export const useUsersStatus = () => {
  const mutation = useMutation((payload) => usersStatus(payload));
  return { ...mutation };
};

import { Box } from "@mui/material";
import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useLocation, useNavigate } from "react-router";
import { Outlet } from "react-router-dom";

const AccountTab = () => {
  let location = useLocation();
  const isAccountPage = location.pathname;
  const [value, setValue] = useState(isAccountPage);
  let navigate = useNavigate();
  const handleChange = (event, newValue) => {
    navigate(newValue);
    setValue(newValue);
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 3 }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Profile" value="/myAccount/profile" />
          <Tab label="Activity" value="/myAccount/activity" />
        </Tabs>
      </Box>
      <Outlet />
    </Box>
  );
};

export default AccountTab;

export const validateEmail = (email) => {
  const emailRegex = /^([a-zA-z0-9.-]+)@([a-zA-z0-9-]+)\.([a-z]{2,6})$/;
  return emailRegex.test(email);
};

export const apiHandleError = (error) => {
  return {
    message: error?.message || "something went wrong",
    status: error?.response?.status || 500,
  };
};

export const validatePassword = (password) => {
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&^])[A-Za-z\d@.#$!%*?&]{8,15}$/;

  return passwordRegex.test(password);
};

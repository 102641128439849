// //Localhost
// const BASE_URL = "http://localhost:3050/api/v1";
// const BASE_REDIRECT_URL = "http://localhost:3000/";
// const BASE_CALLBACK_URL = "http://localhost:3000/auth/callback";

//Production
const BASE_URL = "https://bifrost.enfec.cloud";
const BASE_REDIRECT_URL = "http://localhost:3000/";
const BASE_CALLBACK_URL =
  "http://localhost:3000/account/authentication/callback";

export const ENDPOINTS = {
  getUsers: `${BASE_URL}/account/usersList`,
  register: `${BASE_URL}/api/v1/account/register`,
  passwordActivation: `${BASE_URL}/api/v1/account/password/activation`,
  passwordReset: `${BASE_URL}/api/v1/account/password/reset`,
  userActivation: `${BASE_URL}/account/register/activation`,
  authenticate: `${BASE_URL}/api/v1/account/login`,
  logout: `${BASE_URL}/api/v1/account/logout`,
  twoFactorAuth: `${BASE_URL}/api/v1/account/login/activation`,
  verifyOtp: `${BASE_URL}/account/verifyOtp`,
  forgotPassword: `${BASE_URL}/api/v1/account/password/forgot`,
  signInWithGoogle: `${BASE_URL}/account/authenticate`,
  logInWithGoogleRedirectUrl: `${BASE_REDIRECT_URL}`,
  logInWithGitHubRedirectUrl: `${BASE_CALLBACK_URL}`,
  myAccountProfile: `${BASE_URL}/api/v1/account/profile`,
  changePassword: `${BASE_URL}/api/v1/account/password`,
  updateProfileName: `${BASE_URL}/account/profile`,
  userInvitation: `${BASE_URL}/site/user/invitation`,
  usersStatus: `${BASE_URL}/site/user`,
  organization: `${BASE_URL}/site/organizations`,
  OAuthUrl: `${BASE_URL}/api/v1/account/oauth/url`,
  redirectUrlHook: `${BASE_URL}/api/v1/account/authentication/callback`,
  OAuthRegister: `${BASE_URL}/api/v1/account/oauth/register`,
};

import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";

export const FormLoader = ({ isLoading, message }) => {
  return (
    <div>
      {isLoading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "1rem",
          }}
        >
          <CircularProgress size={20} />
          <Typography
            variant="body1"
            sx={{
              marginLeft: "10px",
              fontWeight: "medium",
            }}
          >
            {message}
          </Typography>
        </Box>
      )}
    </div>
  );
};

import { Grid, IconButton } from "@mui/material";
import React from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
const CustomEyeIcon = ({ passwordShowIcon, onClick, type }) => {
  return (
    <>
      {passwordShowIcon && (
        <Grid className="icons-styles">
          <IconButton onClick={onClick} sx={{ marginRight: "0.3rem" }}>
            {type !== "text" ? (
              <VisibilityOffIcon sx={{ color: "#8390af", cursor: "pointer" }} />
            ) : (
              <VisibilityIcon sx={{ color: "#8390af", cursor: "pointer" }} />
            )}
          </IconButton>
        </Grid>
      )}
    </>
  );
};

export default CustomEyeIcon;

import React, { useEffect } from "react";
import "./App.css";
import DashBoard from "./pages/dashBoard/DashBoard";
import LoginPage from "./pages/login/Login";
import { useStore } from "./store/store";
import { Routes, Route, Navigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import SignUp from "./pages/signUp/SignUp";
import CreateAccount from "./pages/signUp/components/CreateAccount";
import ForgotPassword from "./pages/forgotPassword/ForgotPassword";
import Callback from "./components/Callback";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InfoPage from "./components/infoPage/infoPage";
import Cookies from "js-cookie";
import { nanoid } from "nanoid";
import { COOKIES_KEYS } from "./utils";
function App() {
  const isAuthenticated = useStore((state) => state.isAuthenticated);

  useEffect(() => {
    let uniqueId = Cookies.get("uniqueId");
    if (!uniqueId) {
      uniqueId = nanoid();
      const expirationDate = new Date("2099-12-31");
      Cookies.set(COOKIES_KEYS.uniqueId, uniqueId, { expires: expirationDate });
    }
  }, []);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        onError: () => {
          //To catch errors happening on the queries
          // console.error({ queryError: error }, error);
        },
      },
      mutations: {
        onError: () => {
          //To catch errors happening on the mutations
          // console.error({ muationError: error });
        },
      },
    },
  });
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        {isAuthenticated ? (
          <>
            <ToastContainer
              position="top-right"
              transition={Slide}
              autoClose={3000}
              hideProgressBar={false}
              closeOnClick
              pauseOnHover
              draggable
              theme="light"
            />
            <DashBoard />
          </>
        ) : (
          <>
            <Routes>
              <Route path="/" element={<LoginPage />} />
              <Route
                path="/account/authentication/callback"
                element={<Callback />}
              />
              <Route path="/accountCreate" element={<CreateAccount />} />
              <Route path="/infoPage" element={<InfoPage />} />
              <Route path="/signUp" element={<SignUp />} />
              <Route path="/forgotPassword" element={<ForgotPassword />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </>
        )}
      </QueryClientProvider>
    </div>
  );
}
export default App;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.labelStyle {
  color: rgb(0, 105, 255);
  font-size: 1rem;
  text-transform: lowercase;
}
.textfieldDottoedLine:focus-within {
  outline: rgb(91, 105, 135) dotted 2px !important;
  outline-offset: 1px !important;
  border-radius: none !important;
  z-index: 2 !important;
}
.textfieldDottoedLine:hover {
  border-color: rgb(0, 97, 235) !important;
}
.buttonStyle {
  margin-top: 16px !important;
  border-radius: 10px !important;
  padding: 0px 1rem !important;
  border-radius: 10px !important;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s !important;
  line-height: 44px !important;
  height: 48px !important;
  min-width: 40px !important;
  font-weight: 600 !important;
  max-width: none !important;
  font-size: 16px !important;
  text-decoration: none !important;
  text-align: center !important;
  white-space: nowrap !important;
  border-width: 2px !important;
  border-style: solid !important;
  border-color: transparent !important;
  width: 100% !important;
  text-transform: capitalize !important;
}

.buttonStyle:focus {
  outline: rgb(91, 105, 135) dotted 2px !important;
  outline-offset: 1px !important;
  border-radius: none !important;
  z-index: 2 !important;
}
`, "",{"version":3,"sources":["webpack://./src/pages/forgotPassword/ForgotPassword.css"],"names":[],"mappings":";AACA;EACE,uBAAuB;EACvB,eAAe;EACf,yBAAyB;AAC3B;AACA;EACE,gDAAgD;EAChD,8BAA8B;EAC9B,8BAA8B;EAC9B,qBAAqB;AACvB;AACA;EACE,wCAAwC;AAC1C;AACA;EACE,2BAA2B;EAC3B,8BAA8B;EAC9B,4BAA4B;EAC5B,8BAA8B;EAC9B,wEAAwE;EACxE,4BAA4B;EAC5B,uBAAuB;EACvB,0BAA0B;EAC1B,2BAA2B;EAC3B,0BAA0B;EAC1B,0BAA0B;EAC1B,gCAAgC;EAChC,6BAA6B;EAC7B,8BAA8B;EAC9B,4BAA4B;EAC5B,8BAA8B;EAC9B,oCAAoC;EACpC,sBAAsB;EACtB,qCAAqC;AACvC;;AAEA;EACE,gDAAgD;EAChD,8BAA8B;EAC9B,8BAA8B;EAC9B,qBAAqB;AACvB","sourcesContent":["\n.labelStyle {\n  color: rgb(0, 105, 255);\n  font-size: 1rem;\n  text-transform: lowercase;\n}\n.textfieldDottoedLine:focus-within {\n  outline: rgb(91, 105, 135) dotted 2px !important;\n  outline-offset: 1px !important;\n  border-radius: none !important;\n  z-index: 2 !important;\n}\n.textfieldDottoedLine:hover {\n  border-color: rgb(0, 97, 235) !important;\n}\n.buttonStyle {\n  margin-top: 16px !important;\n  border-radius: 10px !important;\n  padding: 0px 1rem !important;\n  border-radius: 10px !important;\n  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s !important;\n  line-height: 44px !important;\n  height: 48px !important;\n  min-width: 40px !important;\n  font-weight: 600 !important;\n  max-width: none !important;\n  font-size: 16px !important;\n  text-decoration: none !important;\n  text-align: center !important;\n  white-space: nowrap !important;\n  border-width: 2px !important;\n  border-style: solid !important;\n  border-color: transparent !important;\n  width: 100% !important;\n  text-transform: capitalize !important;\n}\n\n.buttonStyle:focus {\n  outline: rgb(91, 105, 135) dotted 2px !important;\n  outline-offset: 1px !important;\n  border-radius: none !important;\n  z-index: 2 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

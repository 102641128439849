import { Alert } from "@mui/material";
import React from "react";

export const FormError = ({ isError, error }) => {
  return (
    <div>
      {isError && (
        <Alert severity="error" variant="outlined" className="alertMsgError">
          {isError && error.message === "Network Error"
            ? "Network Error"
            : isError && error.message}
        </Alert>
      )}
    </div>
  );
};

export const FormSuccess = ({ isSuccess, SuccessMessage }) => {
  return (
    <div>
      {isSuccess && (
        <Alert
          severity="success"
          variant="outlined"
          className="alertMsgSuccess"
        >
          {SuccessMessage && SuccessMessage.response.data}
        </Alert>
      )}
    </div>
  );
};

import React from "react";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import "./Loader.css";

function Loader() {
  return (
    <Box className="loader">
      <CircularProgress />
    </Box>
  );
}

export default Loader;

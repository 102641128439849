// theme.js
import { createTheme, responsiveFontSizes } from "@mui/material/styles";

let theme = createTheme({
  palette: {
    primary: {
      main: "#0061EB",
      light: "#3387FF",
      contrastText: "#fff",
    },
    success: {
      main: "#127C43",
      light: "#0AA653",
      contrastText: "#fff",
    },
    socialAuthButton: {
      main: "#fff",
      light: "#0061EB",
      contrastText: "#24335A",
    },
  },
  typography: {
    fontSize: 14,
    fontFamily: "Poppins, sans-serif",
    fontWeightExtraLight: 200,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightSemiBold: 600,
    fontWeightBold: 700,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ theme, ownerState }) => {
          return {
            backgroundColor: theme.palette[ownerState.color].main,
            color: theme.palette[ownerState.color].contrastText,
            boxShadow: "none",
            "&:hover": {
              backgroundColor: theme.palette[ownerState.color].light,
            },
          };
        },
      },
    },
  },
});

// Make the typography responsive
theme = responsiveFontSizes(theme);

export default theme;

import { Button, Divider, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Name.css";
import ErrorIcon from "@mui/icons-material/Error";
import { useStore } from "../../../../store/store";
import { useUpdateMyAccountProfile } from "../../../../api/iam/apiHooks";
import { FormError, FormLoader } from "../../../../components/form";

const Name = () => {
  const isprofileDetails = useStore((state) => state.profileDetails);
  const isSetProfileDetails = useStore((state) => state.setProfileDetails);
  const [editName, setEditName] = useState(false);
  const [defaultName, setDefaultName] = useState("");
  const [nameFocusValid, setNameFocusValid] = useState(false);

  useEffect(() => {
    if (isprofileDetails !== undefined) {
      setDefaultName(isprofileDetails.name);
    }
  }, [isprofileDetails]);

  const {
    mutateAsync: profileAccountUpdateMutate,
    isLoading,
    isError,
    error,
  } = useUpdateMyAccountProfile();

  const fnUpdatename = async () => {
    if (defaultName === "") {
      setNameFocusValid(true);
    } else {
      setNameFocusValid(false);
      let payload = {
        id: isprofileDetails.id,
        email: isprofileDetails.email,
        full_name: defaultName,
        joined_date: isprofileDetails.joined_date,
      };
      try {
        await profileAccountUpdateMutate(payload);
        setNameFocusValid(false);
        setEditName(false);
        isSetProfileDetails(payload);
      } catch (_) {
        // console.log(error);
      }
    }
  };

  return (
    <div>
      <>
        <Grid className="row">
          <Grid className="col-lg-3">
            <Typography sx={{ fontWeight: 600 }}>Name</Typography>
          </Grid>
          <Grid
            className="col-lg-7"
            sx={{ position: "relative", bottom: editName ? "3rem" : "" }}
          >
            <Typography sx={{ fontWeight: 600 }}>
              {!editName ? (
                defaultName
              ) : (
                <>
                  <br />
                  {isError ? (
                    <FormError isError={isError} error={error} />
                  ) : null}
                  <br />
                  <label
                    style={{
                      fontWeight: 600,
                      color: "031b4e",
                      fontSize: ".875rem",
                    }}
                  >
                    Edit Name{" "}
                    <span
                      style={{
                        color: defaultName !== "" ? "rgb(51, 135, 255)" : "red",
                      }}
                    >
                      *
                    </span>
                  </label>
                  <Grid>
                    <input
                      type="text"
                      style={{ minWidth: "50%" }}
                      onFocus={() => setNameFocusValid(true)}
                      value={defaultName}
                      onChange={(e) => setDefaultName(e.target.value)}
                      className={
                        defaultName === "" && nameFocusValid
                          ? "textFieldProfile errorTextField errorTextFieldDottedLine"
                          : "textFieldProfile textfieldProfileDottoedLine"
                      }
                    />
                    {defaultName === "" && nameFocusValid ? (
                      <Grid
                        className="mt-2"
                        sx={{
                          color: "rgb(202, 12, 12)",
                          fontSize: "14px",
                          fontWeight: 600,
                        }}
                      >
                        <ErrorIcon />
                        Required
                      </Grid>
                    ) : null}
                  </Grid>
                  <Grid className="d-flex justify-content-start">
                    {isLoading ? (
                      <FormLoader
                        isLoading={isLoading}
                        message="Updating Name"
                      />
                    ) : null}
                  </Grid>
                  <Grid className="mt-2">
                    <Button
                      className="profileBtn"
                      disabled={defaultName === "" ? true : false}
                      sx={{
                        opacity: defaultName === "" ? 0.5 : "",
                        textTransform: "capitalize",
                      }}
                      onClick={fnUpdatename}
                    >
                      Save
                    </Button>
                    <Link
                      onClick={() => setEditName(false)}
                      className="profileLinkCancelHover px-3"
                    >
                      Cancel
                    </Link>
                  </Grid>
                </>
              )}
            </Typography>
          </Grid>
          <Grid className="col-lg-2 d-flex justify-content-end">
            <Grid>
              {editName ? null : (
                <Button className="btnStyle" onClick={() => setEditName(true)}>
                  Edit
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid className="py-3">
          <Divider sx={{ borderColor: "gray" }} />
        </Grid>
      </>
    </div>
  );
};

export default Name;

import React, { useState } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import "./OtpVerificationForm.css";
import Cookies from "js-cookie";
import { COOKIES_KEYS, ERRORMESSAGES, validatePassword } from "../../utils";
import { FormLoader, FormError } from "../form";
import { Link, useNavigate } from "react-router-dom";
import { useStore } from "../../store/store";
import { useErrorMessage } from "../../hooks/useErrorMessage";
import { usePasswordResetApi } from "../../api/iam/apiHooks";

const OtpVerificationForm = ({ onSubmit, isLoading, isError, context }) => {
  const [showPasswordReset, setShowPasswordReset] = useState(false);
  const [otpData, setOtpData] = useState({});
  const authStore = useStore((state) => state.setAuth);
  const navigate = useNavigate();
  const { error, handleError } = useErrorMessage();

  const {
    register: otpRegister,
    handleSubmit: otpHandleSubmit,
    watch: otpWatch,
    formState: { errors: otpErrors },
  } = useForm();

  const {
    register: passwordRegister,
    handleSubmit: passwordHandleSubmit,
    formState: { errors: passwordErrors },
  } = useForm();

  const { mutate: passwordResetMutate, isError: passwordError } =
    usePasswordResetApi();

  // Watch the checkbox value
  const isChecked = otpWatch("trust");

  const handleOtpSuccess = (body) => {
    if (context === "signup") {
      setOtpData(body);
      setShowPasswordReset(true);
    } else if (context === "login") {
      Cookies.set(COOKIES_KEYS.accessToken, body.access_token);
      authStore(true);
      navigate("/dashboard");
    }
  };

  const handleOtpError = (error) => {
    handleError(error);
  };

  const handlePasswordReset = async (data) => {
    try {
      let uniqueId = Cookies.get(COOKIES_KEYS.uniqueId);
      const payload = {
        activation_id: otpData.activation_id,
        activation_code: otpData.activation_code,
        password: data.password,
        client_id: uniqueId,
      };

      passwordResetMutate(payload, {
        onSuccess: (response) => {
          Cookies.set(COOKIES_KEYS.accessToken, response.access_token);
          authStore(true);
          navigate("/users");
        },
        onError: (error) => {
          console.log(error);
          handleError(error);
        },
      });
    } catch (error) {
      handleError(error);
    }
  };

  if (showPasswordReset) {
    return (
      <>
        <Grid>
          <h3
            style={{
              fontSize: "32px",
              letterSpacing: "-.5px",
              lineHeight: "40px",
              fontWeight: "700",
            }}
          >
            Create a Strong Password
          </h3>
        </Grid>
        <form onSubmit={passwordHandleSubmit(handlePasswordReset)}>
          {passwordError ? (
            <FormError isError={passwordError} error={error} />
          ) : null}
          <Grid className="d-grid gap-3">
            <Grid>
              <label className="labelStyle">
                Password<span className="text-danger">*</span>
              </label>
              <Grid>
                <input
                  type="password"
                  className="inputStyles textfieldDottoedLine"
                  placeholder="Enter password"
                  {...passwordRegister("password", {
                    required: ERRORMESSAGES.loginPageErrormsg.password,
                    validate: {
                      validPassword: (value) =>
                        validatePassword(value) ||
                        ERRORMESSAGES.loginPageErrormsg.passwordValid,
                    },
                  })}
                />
                {passwordErrors.password && (
                  <span className="text-danger">
                    {passwordErrors.password.message}
                  </span>
                )}
              </Grid>
            </Grid>
            <Grid>
              <Button
                color="success"
                className="SignUpbuttonStyle"
                type="submit"
              >
                Create Password
              </Button>
              {isLoading && (
                <FormLoader isLoading={isLoading} message="Updating password" />
              )}
            </Grid>
          </Grid>
        </form>
      </>
    );
  }

  return (
    <>
      <Grid>
        <Typography className="heading mb-3">Verify its you</Typography>
      </Grid>
      <p style={{ color: "rgb(77, 91, 124)" }}>
        We sent a verification code to your email. Enter the code from the email
        in the field below.
      </p>
      <Grid>
        <form
          onSubmit={otpHandleSubmit((data) =>
            onSubmit(data, handleOtpSuccess, handleOtpError),
          )}
        >
          <FormError isError={isError} error={error} />
          <Typography className="label label-required">6-digit code</Typography>

          <input
            className="textField textfieldDottoedLine"
            name="otp"
            type="number"
            placeholder="Enter 6 digit-code"
            {...otpRegister("otp", {
              required: ERRORMESSAGES.OtpValidationErrormsg.otp,
            })}
          />

          <span className="text-danger">
            {otpErrors.otp && (
              <span className="text-danger">{otpErrors.otp.message}</span>
            )}
          </span>

          <FormControlLabel
            className="pt-2 mt-2"
            control={
              <Checkbox
                {...otpRegister("trust")}
                color="primary"
                sx={{
                  color: isChecked ? "" : " rgb(91, 105, 135)", // Color when unchecked
                  "&.Mui-checked": {
                    color: "rgb(0, 105, 255)", // Color when checked
                  },
                }}
              />
            }
            label="Trust this device for 60 days?"
            sx={{
              display: "flex",
              alignItems: "center",
              color: isChecked ? "rgb(0, 97, 235)" : "rgb(3, 27, 78)",
            }}
          />

          <Button className="verify-button mt-2 d-flex" type="submit">
            Verify Code
          </Button>
          <FormLoader isLoading={isLoading} message="OTP verifying" />
        </form>
      </Grid>

      <Typography className="Need-help mt-4">Need Help?</Typography>
      <p className="py-1 mt-2" style={{ color: "rgb(77, 91, 124)" }}>
        Check out our &nbsp;
        <Link
          className="troubleshoot"
          to=""
          sx={{
            cursor: "pointer",
            "&:hover": { textDecoration: "none" },
          }}
        >
          troubleshooting&nbsp;&nbsp;guide
        </Link>
        &nbsp;or &nbsp;
        <Link
          to="/"
          sx={{
            cursor: "pointer",
            "&:hover": { textDecoration: "none" },
          }}
        >
          try again
        </Link>
      </p>
    </>
  );
};

export default OtpVerificationForm;

import { getApiMethod, postApiMethod } from "../apiMethods";
import { apiHandleError, COOKIES_KEYS, ENDPOINTS } from "../../utils";
import Cookies from "js-cookie";
export const signUpFunction = async (body) => {
  try {
    const response = await postApiMethod(ENDPOINTS.register, body);
    return response.data;
  } catch (error) {
    throw apiHandleError(error);
  }
};

export const signInFunction = async (body) => {
  try {
    const credentials = `${body.email}:${body.password}`;
    const encodedCredentials = btoa(credentials);
    const headers = {
      Authorization: `Basic ${encodedCredentials}`,
    };
    const updateBody = {
      client_id: body.client_id,
    };
    return await postApiMethod(
      ENDPOINTS.authenticate,
      updateBody,
      null,
      headers,
    );
  } catch (error) {
    throw apiHandleError(error);
  }
};
export const logoutFunction = async (body) => {
  const accessToken = Cookies.get(COOKIES_KEYS.accessToken);
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  try {
    const response = await postApiMethod(ENDPOINTS.logout, body, null, headers);

    return response.data;
  } catch (error) {
    throw apiHandleError(error);
  }
};
export const userActivationFunction = async (body) => {
  try {
    const response = await postApiMethod(ENDPOINTS.userActivation, body);
    return response.data;
  } catch (error) {
    throw apiHandleError(error);
  }
};
export const passwordActivationFunction = async (body) => {
  try {
    const response = await postApiMethod(ENDPOINTS.passwordActivation, body);
    return response.data;
  } catch (error) {
    throw apiHandleError(error);
  }
};
export const passwordResetFunction = async (body) => {
  try {
    const response = await postApiMethod(ENDPOINTS.passwordReset, body);
    return response.data;
  } catch (error) {
    throw apiHandleError(error);
  }
};

export const twoFactorAuthFunction = async (body) => {
  try {
    const response = await postApiMethod(ENDPOINTS.twoFactorAuth, body);
    return response.data;
  } catch (error) {
    throw apiHandleError(error);
  }
};
export const signInWithGoogle = async (body) => {
  try {
    const response = await postApiMethod(ENDPOINTS.authenticate, body);
    return response.data;
  } catch (error) {
    throw apiHandleError(error);
  }
};
export const getOAuthUrl = async (body) => {
  try {
    const response = await postApiMethod(ENDPOINTS.OAuthUrl, body);
    return response.data;
  } catch (error) {
    throw apiHandleError(error);
  }
};
export const OAuthRegister = async (body) => {
  try {
    const response = await postApiMethod(ENDPOINTS.OAuthRegister, body);
    return response.data;
  } catch (error) {
    throw apiHandleError(error);
  }
};
export const redirectUrl = async ({ queryKey }) => {
  try {
    let uniqueId = Cookies.get(COOKIES_KEYS.uniqueId);
    const response = await getApiMethod(
      `${ENDPOINTS.redirectUrlHook}/${uniqueId}${queryKey[1]}`,
    );
    return response.data;
  } catch (error) {
    throw apiHandleError(error);
  }
};
export const signInWithGitHub = async (body) => {
  try {
    const response = await postApiMethod(ENDPOINTS.authenticate, body);
    return response.data;
  } catch (error) {
    throw apiHandleError(error);
  }
};

export const resetPasswordFunction = async (body) => {
  try {
    const response = await postApiMethod(ENDPOINTS.forgotPassword, body);
    return response.data;
  } catch (error) {
    throw apiHandleError(error);
  }
};

export const getUsersData = async ({ queryKey }) => {
  const { pageNumber, pageSize } = queryKey[1];
  try {
    const params = {
      pageNumber,
      pageSize,
    };

    const response = await getApiMethod(ENDPOINTS.getUsers, params);
    return response.data;
  } catch (error) {
    throw apiHandleError(error);
  }
};
export const getOrganizationData = async () => {
  try {
    const response = await getApiMethod(ENDPOINTS.organization);
    return response.data;
  } catch (error) {
    throw apiHandleError(error);
  }
};
export const getInviteUsersData = async (body) => {
  try {
    const response = await postApiMethod(ENDPOINTS.getUsers, body);
    return response.data;
  } catch (error) {
    throw apiHandleError(error);
  }
};
export const getInviteSelectedUsers = async (body, id) => {
  try {
    return await postApiMethod(ENDPOINTS.userInvitation, body, { id });
  } catch (error) {
    throw apiHandleError(error);
  }
};
export const getMyAccountProfile = async () => {
  try {
    const response = await getApiMethod(ENDPOINTS.myAccountProfile);
    return response.data;
  } catch (error) {
    throw apiHandleError(error);
  }
};

export const passwordUpdateFunction = async (body) => {
  const accessToken = Cookies.get(COOKIES_KEYS.accessToken);
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  try {
    const response = await postApiMethod(
      ENDPOINTS.changePassword,
      body,
      null,
      headers,
    );
    return response.data;
  } catch (error) {
    throw apiHandleError(error);
  }
};

export const updateMyAccountProfile = async (body) => {
  try {
    const response = await postApiMethod(ENDPOINTS.updateProfileName, body);
    return response.data;
  } catch (error) {
    throw apiHandleError(error);
  }
};
export const usersStatus = async (payload) => {
  const { id, status } = payload;
  try {
    const response = await postApiMethod(
      `${ENDPOINTS.usersStatus}/${id}/${status}`,
    );
    return response.data;
  } catch (error) {
    throw apiHandleError(error);
  }
};

export const ERRORMESSAGES = {
  loginPageErrormsg: {
    password: "Password is required",
    passwordValid: "Password is Invalid",
    email: "Email is required",
    validEmail: "Email Address must be valid ",
  },
  OtpValidationErrormsg: {
    otp: "Verification code required",
  },
  changePasswordErrorMsg: {
    Required: "Required",
    passwordRequired: "Password is required",
    confirmPasswordRequired: "Password confirmation is required",
    passwordInValid: "Passwords don't match",
    sucessMessage: "Password Updated Successfully",
  },
};
